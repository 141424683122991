import { makeVar, gql } from '@apollo/client';
import { browserName } from 'react-device-detect';

export const siteLoading = makeVar(false);

export const GET_SITE_LOADING = gql`
  query getSiteLoading {
    siteLoading @client
  }
`;

let _theCardCount = 24;
// if (browserName == 'Safari') {
//   _theCardCount = 12;
// }

export const theCardCount = makeVar(_theCardCount);

export const GET_CARD_COUNT = gql`
  query getTheCardCount {
    theCardCount @client
  }
`;
