import { gql } from '@apollo/client';


export const GET_HOMEPAGE_BOARD = gql`
query getVisionBoard(
  $filter: String!
) {
  getDoritosLotbLookupListing (
    defaultLanguage: "en"
    filter: $filter
    sortBy: "sortdate"
    sortOrder: "desc"
    first: 3
  ) {
    edges {
      node {
        id
        title
        sortdate
        lookuptype
        lookupobject {
          ... on object_DoritosLotbChallenge {
            slug
            status
            topprize
            card
            blurb
            assignment
            category {
              ... on object_DoritosLotbChallengeType {
                name
                icon {
                  ... on asset {
                    fullpath
                  }
                }
              }
            }
            image {
              fullpath
            }
          }
        }
      }
    }
  }
}
`;

export const GET_VISION_BOARD = gql`
query getVisionBoard(
  $filter: String!
  $sort: [String]
  $order: [String]
  $first: Int
  $after: Int
) {
  getDoritosLotbLookupListing (
    defaultLanguage: "en"
    filter: $filter
    sortBy: $sort
    sortOrder: $order
    first: $first
    after: $after
  ) {
    totalCount
    edges {
      node {
        id
        title
        lookuptype
        sortdate
        lookupobject {
          ... on object_DoritosLotbChallenge {
            slug
            status
            topprize
            card
            blurb
            category {
              ... on object_DoritosLotbChallengeType {
                name
                icon {
                  ... on asset {
                    fullpath
                  }
                }
              }
            }
            image {
              fullpath
            }
          }
          ... on object_DoritosLotbWinner {
            slug
            likes
            place
            prize
            blurb
            thumbnail {
              fullpath
            }
            user {
              ... on object_DoritosLotbUser {
                username
                avatar {
                  ... on object_DoritosLotbAvatar {
                    image {
                      fullpath
                    }
                  }
                }
              }
            }
            challenge {
              ... on object_DoritosLotbChallenge {
                category {
                  ... on object_DoritosLotbChallengeType {
                    name
                    icon {
                      ... on asset {
                        fullpath
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

export const GET_VISION_BOARD_WINNERS = gql`
query getVisionBoardWinners(
  $filter: String!
  $sort: [String]
  $order: [String]
  $first: Int
  $after: Int
) {
  getDoritosLotbWinnerListing (
    defaultLanguage: "en"
    filter: $filter
    sortBy: $sort
    sortOrder: $order
    first: $first
    after: $after
  ) {
    totalCount
    edges {
      node {
        id
        title
        slug
        sortdate
        likes
        place
        prize
        blurb
        thumbnail {
          fullpath
        }
        user {
          ... on object_DoritosLotbUser {
            username
            avatar {
              ... on object_DoritosLotbAvatar {
                image {
                  fullpath
                }
              }
            }
          }
        }
        challenge {
          ... on object_DoritosLotbChallenge {
            category {
              ... on object_DoritosLotbChallengeType {
                name
                icon {
                  ... on asset {
                    fullpath
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
`;

export const GET_VISION_BOARD_CHALLENGES = gql`
query getVisionBoardChallenges(
  $filter: String!
  $sort: [String]
  $order: [String]
  $first: Int
  $after: Int
) {
  getDoritosLotbChallengeListing (
    defaultLanguage: "en"
    filter: $filter
    sortBy: $sort
    sortOrder: $order
    first: $first
    after: $after
  ) {
    totalCount
    edges {
      node {
        id
        title
        slug
        sortdate
        status
        topprize
        card
        blurb
        closedtimestamp
        category {
          ... on object_DoritosLotbChallengeType {
            name
            icon {
              ... on asset {
                fullpath
              }
            }
          }
        }
        image {
          fullpath
        }
      }
    }
  }
}
`;

export const GET_VISION_BOARD_CHALLENGES_BY_ID = gql`
query getVisionBoardChallenges(
  $ids: String
  $sort: [String]
  $order: [String]
  $first: Int
  $after: Int
) {
  getDoritosLotbChallengeListing (
    defaultLanguage: "en"
    ids: $ids
    sortBy: $sort
    sortOrder: $order
    first: $first
    after: $after
  ) {
    totalCount
    edges {
      node {
        id
        title
        slug
        sortdate
        status
        topprize
        card
        blurb
        closedtimestamp
        category {
          ... on object_DoritosLotbChallengeType {
            name
            icon {
              ... on asset {
                fullpath
              }
            }
          }
        }
        image {
          fullpath
        }
      }
    }
  }
}
`;

export const GET_CATEGORIES = gql`
{
  getDoritosLotbChallengeTypeListing (
    defaultLanguage: "en"
  ) {
    edges {
      node {
        id
        name
      }
    }
  }
}
`;

export const GET_TAGS = gql`
{
  getDoritosLotbSubmissionTagListing(
    defaultLanguage:"en"
  ){
    totalCount
    edges{
      node{
        id
        name
      }
    }
  }
}
`;
