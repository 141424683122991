import React from 'react';
import ImageComponent from './Image';

const BackgroundImage = (props) => {
  return (
    <>
      <ImageComponent
        isBackground={true}
        className='background-image'
        alt={props.data.alt}
        srcSet={
          props.data.mobile.url +
          ' ' +
          props.data.mobile.width +
          'w, ' +
          props.data.desktop.url +
          ' ' +
          props.data.desktop.width +
          'w'
        }
        sizes={
          '(max-width: 992px) ' +
          props.data.mobile.width +
          'px, ' +
          props.data.desktop.width +
          'px'
        }
        url={props.data.desktop.url}
      />
    </>
  );
};

export default BackgroundImage;
