import React from 'react';
import { Helmet } from 'react-helmet';
import ChallengeComponent from '../components/challenges/Challenge';

const Challenge = (props) => {
  return (
    <>
      <Helmet>
        <title> - Challenge</title>
      </Helmet>
      <ChallengeComponent id={props.id} />
    </>
  );
};

export default Challenge;
