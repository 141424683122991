import React from 'react';
import { useHistory } from 'react-router-dom';

import { ReactComponent as EditProfileIcon } from '../../assets/svg/edit-profile-icon.svg';
import { ReactComponent as FacebookIcon } from '../../assets/svg/facebook-icon.svg';
import { ReactComponent as YoutubeIcon } from '../../assets/svg/youtube-icon.svg';
// import { ReactComponent as InstagramIcon } from '../../assets/svg/instagram-icon.svg';
import { ReactComponent as TikTokIcon } from '../../assets/svg/tiktok-icon.svg';
import { ReactComponent as TwitterIcon } from '../../assets/svg/twitter-icon.svg';
import { ReactComponent as SnapchatIcon } from '../../assets/svg/snapchat-icon.svg';
import { ReactComponent as LinkedinIcon } from '../../assets/svg/linkedin-icon.svg';
import { ReactComponent as TwitchIcon } from '../../assets/svg/twitch-icon.svg';
import { ReactComponent as BehanceIcon } from '../../assets/svg/behance-icon.svg';
import { ReactComponent as BigSplash } from '../../assets/svg/splash-1.svg';
import { ReactComponent as HollowChip } from '../../assets/svg/hollow-chip.svg';
import { ReactComponent as Mountains } from '../../assets/svg/mountains.svg';
import instagramIcon from '../../assets/img/instagram-icon.png';
import ImageComponent from '../Image';

const UserDetails = ({ data, isCurrentUser }) => {
  const history = useHistory();

  return (
    <>
      <div className='user-details'>
        <div className='background-image-container'>
          <ImageComponent
            className='background-image'
            url={data.profileheader.image.fullpath}
            alt={data.profileheader.name}
          />
          <div className='bottom-gradient'></div>
        </div>
        <div className='graphics'>
          <BigSplash className='big-splash' />
          <HollowChip className='yellow-hollow-chip' />
          <HollowChip className='red-hollow-chip-1' />
          <HollowChip className='red-hollow-chip-2' />
          <Mountains className='mountains' />
        </div>
        <div className='content'>
          <div className='avatar-container'>
            <ImageComponent
              className='avatar'
              url={data.avatar.image.fullpath}
              alt={data.avatar.name}
            />
          </div>
          <div className='user-info'>
            {isCurrentUser && (
              <p
                className='edit-profile-link'
                onClick={() => history.push(`/users/${data.username}/edit`)}
              >
                <EditProfileIcon />
                <span>Edit Profile</span>
              </p>
            )}
            <h1>{data.username}</h1>
            <h2>{data.tier}</h2>
          </div>

          <div className='socials'>
            {data.facebook && data.facebook !== '' && (
              <a href={data.facebook} rel='noreferrer' target='_blank'>
                <FacebookIcon />
              </a>
            )}
            {data.youtube && data.youtube !== '' && (
              <a href={data.youtube} rel='noreferrer' target='_blank'>
                <YoutubeIcon />
              </a>
            )}
            {data.instagram && data.instagram !== '' && (
              <a href={data.instagram} rel='noreferrer' target='_blank'>
                {/* <InstagramIcon /> */}
                <img
                  className='instagram-icon'
                  alt='instagram icon'
                  src={instagramIcon}
                />
              </a>
            )}
            {data.tiktok && data.tiktok !== '' && (
              <a href={data.tiktok} rel='noreferrer' target='_blank'>
                <TikTokIcon />
              </a>
            )}
            {data.twitter && data.twitter !== '' && (
              <a href={data.twitter} rel='noreferrer' target='_blank'>
                <TwitterIcon />
              </a>
            )}
            {data.snapchat && data.snapchat !== '' && (
              <a href={data.snapchat} rel='noreferrer' target='_blank'>
                <SnapchatIcon />
              </a>
            )}
            {data.twitch && data.twitch !== '' && (
              <a href={data.twitch} rel='noreferrer' target='_blank'>
                <TwitchIcon />
              </a>
            )}
            {data.linkedin && data.linkedin !== '' && (
              <a href={data.linkedin} rel='noreferrer' target='_blank'>
                <LinkedinIcon />
              </a>
            )}
            {data.behance && data.behance !== '' && (
              <a href={data.behance} rel='noreferrer' target='_blank'>
                <BehanceIcon />
              </a>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default UserDetails;
