import React, { useEffect, useRef, useState } from 'react';
import ChallengeSubmissionForm from './ChallengeSubmissionForm';
import { gsap } from 'gsap';
import {
  SUBMISSION_ADD,
  SUBMISSION_UPDATE,
} from '../../graphql/mutations/submissions.js';
import { useLazyQuery, useMutation } from '@apollo/client';
import { GET_SUBMISSIONS } from '../../graphql/queries/submissions.js';
import ComponentLoader from '../ComponentLoader';
import ErrorComponent from '../ErrorComponent';
import Button from 'react-bootstrap/Button';

const ChallengeSubmissions = ({
  challengeId,
  userId,
  open,
  maxAttachments,
  maxEntries,
  prizeTypes,
}) => {
  const [adding, setAdding] = useState(false);
  const [editing, setEditing] = useState(false);
  const [visibleSubmissionIndex, setVisibleSubmissionIndex] = useState(0);
  const [newSubmissionId, setNewSubmissionId] = useState(-1);
  const [data, setData] = useState([]);
  const [allTags, setAllTags] = useState(null);
  const [loading, setLoading] = useState(true);
  const [successfulSubmission, setSuccessfulSubmission] = useState(false);

  const containerRef = useRef(null);

  useEffect(() => {
    getSubmissions({
      variables: {
        filter: `{"$and":[{"uid":${userId}},{"cid":${challengeId}}]}`,
      },
    });
  }, []);

  const [getSubmissions] = useLazyQuery(GET_SUBMISSIONS, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      const submissions = data.getDoritosLotbSubmissionListing.edges;
      setAllTags(data.getDoritosLotbSubmissionTagListing.edges);

      if (!submissions || submissions.length === 0) {
        // no submissions
        setLoading(false);
      } else {
        setData(submissions);
      }
    },
    onError: (error) => {
      return <ErrorComponent />;
    },
  });

  useEffect(() => {
    if (editing) {
      scrollToTop();
    }
  }, [editing]);

  useEffect(() => {
    if (data && data.length) {
      setLoading(false);
    } else {
      setLoading(true);
    }
  }, [data]);

  useEffect(() => {
    if (newSubmissionId !== -1) {
      setAdding(true);
      setLoading(false);
    }
  }, [newSubmissionId]);

  const [addSubmission] = useMutation(SUBMISSION_ADD, {
    onCompleted: (data) => {
      setNewSubmissionId(parseInt(data.createDoritosLotbSubmission.output.id));
    },
    onError: (error) => {
      alert('Error adding submission. Reloading challenge.');
      window.location.reload();
      return <ErrorComponent />;
    },
  });

  const [updateSubmission] = useMutation(SUBMISSION_UPDATE, {
    onCompleted: (_data) => {
      if (_data.updateDoritosLotbSubmission.success) {
        // successful mutation

        scrollToTop();

        setSuccessfulSubmission(true);
        setTimeout(() => {
          setSuccessfulSubmission(false);
        }, 3000);

        let index = -1;

        if (data && data.length) {
          index = data.findIndex(
            (submission) =>
              submission.node.id === _data.updateDoritosLotbSubmission.output.id
          );
        }
        if (index === -1) {
          // user added a submission. Add it to our data
          const newData = {
            node: {
              ..._data.updateDoritosLotbSubmission.output,
              cid: challengeId,
              uid: userId,
            },
          };
          let newArr = [...data];
          newArr.push(newData);
          setLoading(true);
          setVisibleSubmissionIndex(newArr.length - 1);
          setData(newArr);
        }

        setAdding(false);
        setEditing(false);
      } else {
        // unsuccessful
        alert('Error saving submission. Reloading challenge.');
        window.location.reload();
        return <ErrorComponent />;
      }
    },
    onError: (error) => {
      return <ErrorComponent />;
    },
  });

  const scrollToTop = () => {
    gsap.to(window, {
      scrollTo: containerRef.current.offsetTop - 100,
    });
  };

  const selectSubmission = (index) => {
    setVisibleSubmissionIndex(index);
  };
  const triggerNewSubmission = () => {
    // get our submission ID
    addSubmission({ variables: { cid: challengeId, uid: userId } });
  };
  const triggerUpdate = (submission) => {
    updateSubmission({
      variables: {
        ...submission,
        id: parseInt(submission.id),
      },
    });
  };

  const onCancel = () => {
    if (adding) {
      setAdding(false);
    }
  };

  if (loading) return <ComponentLoader />;

  return (
    <div className='challenge-submissions' ref={containerRef}>
      {!adding && !editing && data && data.length > 0 && (
        <>
          <h2>Your Submissions</h2>
          <p className='max-entries'>(Max. {maxEntries})</p>
        </>
      )}
      {adding && !editing && open && <h2>Add Submission</h2>}
      {!adding && editing && <h2>Edit Submission</h2>}
      {!adding &&
        !editing &&
        open &&
        (data.length == 0) && (
          <Button variant='primary' className={'add-submission'} onClick={triggerNewSubmission}>
            Add Submission
          </Button>
        )}
      {!adding &&
        !editing &&
        open &&
        (data.length > 0 && data.length < maxEntries) && (
          <p className='new-submission' onClick={triggerNewSubmission}>
            New Submission
          </p>
          )}
      {successfulSubmission && (
        <p className='error'>Your submission has been received.</p>
      )}

      {/* Not adding a new submission */}
      {!adding && data && data.length > 0 && (
        <>
          {!editing && (
            <>
              {/* Submission Selector Buttons */}
              <div className='submission-buttons'>
                {data.map((submission, index) => (
                  <div
                    key={index}
                    className={`submission-buttons-button${
                      index === visibleSubmissionIndex ? ' active' : ''
                    }`}
                    onClick={() => selectSubmission(index)}
                  >
                    {index + 1}
                  </div>
                ))}
              </div>
            </>
          )}

          {/* List of Current Submissions */}
          <div className='submission-entries'>
            {data &&
              data.length &&
              data.map((submission, index) => (
                <div
                  key={index}
                  className={`submission-details${
                    index === visibleSubmissionIndex ? ' active' : ''
                  }`}
                >
                  <ChallengeSubmissionForm
                    prizeTypes={prizeTypes}
                    submissionData={submission}
                    key={index}
                    triggerUpdate={triggerUpdate}
                    editing={editing}
                    setEditing={setEditing}
                    allTags={allTags}
                    open={open}
                    challengeId={challengeId}
                    maxAttachments={maxAttachments}
                  />
                </div>
              ))}
          </div>
        </>
      )}

      {adding && open && (
        <ChallengeSubmissionForm
          prizeTypes={prizeTypes}
          submissionData={null}
          newSubmissionId={newSubmissionId}
          triggerUpdate={triggerUpdate}
          onCancel={onCancel}
          editing={true}
          setEditing={setEditing}
          allTags={allTags}
          challengeId={challengeId}
          maxAttachments={maxAttachments}
        />
      )}
    </div>
  );
};

export default ChallengeSubmissions;
