import React from 'react';
import { Helmet } from 'react-helmet';
import { useReactiveVar } from '@apollo/client';
import { userLogInData } from '../../graphql/reactivities/user';
import {
  GET_USER_STATS_LOGGED_OUT,
  GET_USER_STATS_LOGGED_IN,
} from '../../graphql/queries/user.js';
import { useQuery } from '@apollo/client';
import UserDetails from './UserDetails';
import UserProgress from './UserProgress';
import UserStats from './UserStats';
import UserInterests from './UserInterests';
import PageLoader from '../PageLoader';
import UserChallenges from './UserChallenges';
import { Redirect } from 'react-router';
import ErrorComponent from '../ErrorComponent';

const UserComponent = ({ username, metadata }) => {
  const userLogIn = useReactiveVar(userLogInData);

  const uid = userLogIn ? userLogIn.id : 0;
  const isMe = userLogIn && userLogIn.username == username;

  let query = GET_USER_STATS_LOGGED_OUT;
  if (userLogIn && userLogIn.username == username) {
    query = GET_USER_STATS_LOGGED_IN;
  }

  let queryParams = {
    variables: {
      filter: `{"username": "${username}"}`,
      metaFilter: `{"uid":${uid}}`,
    },
  };
  isMe && (queryParams.fetchPolicy = 'no-cache');

  const { data, loading, error } = useQuery(query, queryParams);

  if (loading || !data) return <PageLoader />;
  if (error) return <ErrorComponent />;
  if (!data.getDoritosLotbUserListing.edges[0]) return <Redirect to='/404' />;

  const profile = data.getDoritosLotbUserListing.edges[0].node;

  const wins = data.getDoritosLotbWinnerListing
    ? data.getDoritosLotbWinnerListing.totalCount
    : 0;
  let submissions = [];
  let yes = [];
  let maybe = [];
  let no = [];
  if (data.getDoritosLotbMetaDataListing) {
    if (data.getDoritosLotbMetaDataListing.totalCount > 0) {
      data.getDoritosLotbMetaDataListing.edges.forEach((item) => {
        if (item.node.activity == 'submission') submissions.push(item.node.oid);
        if (item.node.activity == 'working on it') yes.push(item.node.oid);
        if (item.node.activity == 'interested') maybe.push(item.node.oid);
        if (item.node.activity == 'not interested') no.push(item.node.oid);
      });
    }
  }

  return (
    <>
      <Helmet>
        <title> - User - {username}</title>
      </Helmet>
      <section className='user'>
        {/* Details */}
        <UserDetails data={profile} isCurrentUser={isMe} username={username} />
        {/* Progress */}
        <UserProgress
          currentPoints={profile.points}
          maximumLevelMessage={metadata.levelsCompleted}
        />
        {/* Stats */}
        <UserStats
          prizes={profile.prizes}
          wins={wins}
          submissions={submissions}
          isMe={isMe}
        />
        {/* Interests */}
        <UserInterests interests={profile.interests} />
        {/* CHALLENGES */}
        <UserChallenges
          data={data}
          uid={parseInt(profile.id)}
          wins={wins}
          submissions={submissions}
          yes={yes}
          maybe={maybe}
          no={no}
          isMe={isMe}
        />
      </section>
    </>
  );
};

export default UserComponent;
