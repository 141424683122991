import React from 'react';

import prizesIcon from '../../assets/svg/prizes-icon.svg';
import prizeRibbonIcon from '../../assets/svg/prize-ribbon-icon.svg';
import submissionsIcon from '../../assets/svg/submissions-icon.svg';

const UserStats = ({ prizes, wins, submissions, isMe }) => {
  return (
    <>
      <div className='user-stats'>
        <h3>{isMe ? `your ` : ``}stats</h3>
        <div className='content'>
          {/* Prize Money */}
          {isMe && (
            <div className='stat'>
              <img alt='prizes' src={prizesIcon} />
              <p role='heading' aria-level='3' className='info-label'>
                Prizes
              </p>
              <p>{prizes ? prizes : '$0'}</p>
            </div>
          )}
          {/* Wins */}
          <div className='stat'>
            <img alt='wins' src={prizeRibbonIcon} />
            <p role='heading' aria-level='3' className='info-label'>
              Wins
            </p>
            <p>{wins}</p>
          </div>
          {/* Submissions */}
          {isMe && (
            <div className='stat'>
              <img alt='submissions' src={submissionsIcon} />
              <p role='heading' aria-level='3' className='info-label'>
                Submissions
              </p>
              <p>{submissions.length || 0}</p>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default UserStats;
