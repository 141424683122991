import React, { useState, useRef, createRef } from 'react';
// import BackgroundImage from '../BackgroundImage';
import SearchInput from '../SearchInput';
import ScrollableContainer from '../ScrollableContainer';
import VisionBoard from '../VisionBoard';
import { useQuery } from '@apollo/client';
import { useReactiveVar } from '@apollo/client';
import { theCardCount } from '../../graphql/reactivities/site';
import {
  GET_VISION_BOARD,
  GET_VISION_BOARD_WINNERS,
} from '../../graphql/queries/visionboard.js';
import { GET_WINNERS_PAGE } from '../../graphql/queries/winners.js';
import PageLoader from '../PageLoader';
import { formatContentData } from '../../Helpers';
import ErrorComponent from '../ErrorComponent';

const WinnersComponent = ({ metadata }) => {
  const cardCount = useReactiveVar(theCardCount);
  const [queryGQL, setQueryGQL] = useState(GET_VISION_BOARD_WINNERS);
  const [queryObject, setQueryObject] = useState('getDoritosLotbWinnerListing');
  const [queryVariables, setQueryVariables] = useState({
    filter: `{"title":{"$like": "%"}}`,
    sort: 'sortdate',
    order: 'desc',
    first: cardCount,
    after: 0,
  });

  const [selectedTagIndex, setSelectedTagIndex] = useState(0);
  const scrollableContainerRef = useRef(null);
  const visionBoardRef = useRef(null);
  const searchInputRef = useRef(null);

  let tagButtonRefs = [];

  const doSearch = (searchText) => {
    visionBoardRef.current.triggerReset();
    scrollableContainerRef.current.elementClicked(tagButtonRefs[0].current);
    setSelectedTagIndex(0);

    setQueryGQL(GET_VISION_BOARD);
    setQueryObject('getDoritosLotbLookupListing');
    setQueryVariables({
      filter: `{"$and":[{"lookuptype":"winner"},{"$or":[{"title":{"$like":"%${searchText}%"}},{"tags":{"$like":"%${searchText}%"}}]}]}`,
      sort: 'title',
      order: 'asc',
      first: cardCount,
      after: 0,
    });
  };

  function filterByTag(tag) {
    visionBoardRef.current.triggerReset();
    if (tag === 'all') {
      setQueryGQL(GET_VISION_BOARD_WINNERS);
      setQueryObject('getDoritosLotbWinnerListing');
      setQueryVariables({
        filter: `{"title":{"$like": "%"}}`,
        sort: 'sortdate',
        order: 'desc',
        first: cardCount,
        after: 0,
      });
    } else if (tag === 'likes') {
      setQueryGQL(GET_VISION_BOARD_WINNERS);
      setQueryObject('getDoritosLotbWinnerListing');
      setQueryVariables({
        filter: `{"title":{"$like": "%"}}`,
        sort: 'likes',
        order: 'desc',
        first: cardCount,
        after: 0,
      });
    } else {
      setQueryGQL(GET_VISION_BOARD);
      setQueryObject('getDoritosLotbLookupListing');
      setQueryVariables({
        filter: `{"$and":[{"lookuptype":"winner"},{"tags":{"$like": "%${tag}%"}}]}`,
        sort: 'title',
        order: 'asc',
        first: cardCount,
        after: 0,
      });
    }
  }

  const loadMoreCards = function () {
    let variables = { ...queryVariables };
    variables.after += cardCount;
    setQueryVariables(variables);
  };

  function tagClick(e) {
    searchInputRef.current.triggerClearSearch();
    scrollableContainerRef.current.elementClicked(e.target);
    setSelectedTagIndex(e.target.dataset.id);
    filterByTag(e.target.dataset.slug);
  }

  const searchCleared = () => {
    tagButtonRefs[0].current.click();
  };

  let tags = [
    {
      id: 0,
      name: 'all',
      slug: 'all',
    },
    {
      id: 1,
      name: 'likes',
      slug: 'likes',
    },
  ];

  const { data, loading, error } = useQuery(GET_WINNERS_PAGE, {
    variables: { filter: `{"slug": "winners"}` },
  });

  if (loading || !data) return <PageLoader />;
  if (error || !data.getDoritosLotbContentListing.edges[0])
    return <ErrorComponent />;

  const setup = data.getDoritosLotbContentListing.edges.reduce(function (
    map,
    obj
  ) {
    map[obj.node.slug] = obj.node;
    return map;
  },
  {});

  const page = formatContentData(setup, 'winners');

  if (data.getDoritosLotbChallengeTypeListing.edges[0]) {
    let id = tags.length;
    const metadata = data.getDoritosLotbChallengeTypeListing.edges.forEach(
      (cat) => {
        tags.push({
          id: id,
          name: cat.node.name,
          slug: cat.node.name,
        });
        id++;
      }
    );
  }

  let backgroundStyle = {};
  if (page.image.background) {
    backgroundStyle.backgroundImage = 'url(' + page.image.background.desktop.url + ')';
  }

  return (
    <>
      <section className='vision-board-page'>
        {page.image.background && (
          <div className='background-image-container' style={backgroundStyle}>
            {/*<BackgroundImage data={page.image.background} />*/}
          </div>
        )}

        {page.metadata.search && (
          <SearchInput
            ref={searchInputRef}
            placeholder={page.metadata.searchText}
            onSubmit={doSearch}
            searchCleared={searchCleared}
          />
        )}

        <ScrollableContainer ref={scrollableContainerRef} scrollerColor='red'>
          {tags.map((item, index) => {
            const newRef = createRef();
            tagButtonRefs.push(newRef);
            return (
              <li
                ref={newRef}
                key={index}
                data-name={item.name}
                data-slug={item.slug}
                data-id={item.id}
                onClick={tagClick}
                tabIndex='0'
                className={item.id == selectedTagIndex ? 'active' : ''}
              >
                <span>{item.name}</span>
              </li>
            );
          })}
        </ScrollableContainer>

        <VisionBoard
          ref={visionBoardRef}
          query={queryGQL}
          object={queryObject}
          variables={queryVariables}
          loadMoreCards={loadMoreCards}
          metadata={metadata}
        />
      </section>
    </>
  );
};

export default WinnersComponent;
