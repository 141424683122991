import { gql } from '@apollo/client';

export const GET_SUBMISSIONS = gql`
  query getSubmissions($filter: String!) {
    getDoritosLotbSubmissionListing(filter: $filter) {
      edges {
        node {
          id
          title
          cid
          uid
          summary
          tags
          prizetype
          attachments {
            ... on object_DoritosLotbSubmissionAttachment {
              id
              attachmenttype
              attachment {
                ... on asset {
                  fullpath
                  type
                }
              }
            }
          }
        }
      }
    }
    getDoritosLotbSubmissionTagListing(defaultLanguage: "en") {
      totalCount
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;
