import React, { useState, useRef, createRef } from 'react';
// import BackgroundImage from '../BackgroundImage';
import SearchInput from '../SearchInput';
import ScrollableContainer from '../ScrollableContainer';
import VisionBoard from '../VisionBoard';
import { useQuery } from '@apollo/client';
import { useReactiveVar } from '@apollo/client';
import { theCardCount } from '../../graphql/reactivities/site';
import { GET_VISION_BOARD } from '../../graphql/queries/visionboard.js';
import { GET_CONTENT } from '../../graphql/queries/content.js';
import PageLoader from '../PageLoader';
import { formatContentData } from '../../Helpers';
import ErrorComponent from '../ErrorComponent';

const AllActivityComponent = ({ metadata }) => {
  const cardCount = useReactiveVar(theCardCount);
  const [queryGQL, setQueryGQL] = useState(GET_VISION_BOARD);
  const [queryObject, setQueryObject] = useState('getDoritosLotbLookupListing');
  const [queryVariables, setQueryVariables] = useState({
    filter: `{"$or":[{"lookuptype": "winner"},{"lookuptype": "challenge"}]}`,
    sort: 'sortdate',
    order: 'desc',
    first: cardCount,
    after: 0,
  });

  const [selectedTagIndex, setSelectedTagIndex] = useState(0);
  const scrollableContainerRef = useRef(null);
  const visionBoardRef = useRef(null);
  const searchInputRef = useRef(null);

  let tagButtonRefs = [];

  const doSearch = (searchText) => {
    visionBoardRef.current.triggerReset();
    scrollableContainerRef.current.elementClicked(tagButtonRefs[0].current);
    setSelectedTagIndex(0);

    setQueryVariables({
      filter: `{"$or":[{"title":{"$like":"%${searchText}%"}},{"tags":{"$like":"%${searchText}%"}}]}`,
      sort: 'title',
      order: 'asc',
      first: cardCount,
      after: 0,
    });
  };

  function filterByTag(tag) {
    visionBoardRef.current.triggerReset();
    if (tag === 'newest') {
      setQueryVariables({
        filter: `{"$or":[{"lookuptype": "winner"},{"lookuptype": "challenge"}]}`,
        sort: 'sortdate',
        order: 'desc',
        first: cardCount,
        after: 0,
      });
    } else if (tag === 'oldest') {
      setQueryVariables({
        filter: `{"$or":[{"lookuptype": "winner"},{"lookuptype": "challenge"}]}`,
        sort: 'sortdate',
        order: 'asc',
        first: cardCount,
        after: 0,
      });
    } else if (tag === 'asc') {
      setQueryVariables({
        filter: `{"$or":[{"lookuptype": "winner"},{"lookuptype": "challenge"}]}`,
        sort: 'title',
        order: 'asc',
        first: cardCount,
        after: 0,
      });
    } else if (tag === 'desc') {
      setQueryVariables({
        filter: `{"$or":[{"lookuptype": "winner"},{"lookuptype": "challenge"}]}`,
        sort: 'title',
        order: 'desc',
        first: cardCount,
        after: 0,
      });
    }
  }

  const loadMoreCards = function () {
    let variables = { ...queryVariables };
    variables.after += cardCount;
    setQueryVariables(variables);
  };

  function tagClick(e) {
    searchInputRef.current.triggerClearSearch();
    scrollableContainerRef.current.elementClicked(e.target);
    setSelectedTagIndex(e.target.dataset.id);
    filterByTag(e.target.dataset.slug);
  }

  const searchCleared = () => {
    tagButtonRefs[0].current.click();
  };

  const tags = [
    {
      id: 0,
      name: 'newest',
      slug: 'newest',
    },
    {
      id: 1,
      name: 'oldest',
      slug: 'oldest',
    },
    {
      id: 2,
      name: 'title (a-z)',
      slug: 'asc',
    },
    {
      id: 3,
      name: 'title (z-a)',
      slug: 'desc',
    },
  ];

  const { data, loading, error } = useQuery(GET_CONTENT, {
    variables: { filter: `{"slug": "all-activity"}` },
  });

  if (loading || !data) return <PageLoader />;
  if (error || !data.getDoritosLotbContentListing.edges[0])
    return <ErrorComponent />;

  const setup = data.getDoritosLotbContentListing.edges.reduce(function (
    map,
    obj
  ) {
    map[obj.node.slug] = obj.node;
    return map;
  },
  {});

  const page = formatContentData(setup, 'all-activity');

  let backgroundStyle = {};
  if (page.image.background) {
    backgroundStyle.backgroundImage = 'url(' + page.image.background.desktop.url + ')';
  }

  return (
    <>
      <section className='vision-board-page'>
        {page.image.background && (
          <div className='background-image-container' style={backgroundStyle}>
            {/*<BackgroundImage data={page.image.background} />*/}
          </div>
        )}

        {page.metadata.search && (
          <SearchInput
            ref={searchInputRef}
            placeholder={page.metadata.searchText}
            onSubmit={doSearch}
            searchCleared={searchCleared}
          />
        )}

        <ScrollableContainer
          ref={scrollableContainerRef}
          scrollerColor='orange'
        >
          {tags.map((item) => {
            const newRef = createRef();
            tagButtonRefs.push(newRef);
            return (
              <li
                ref={newRef}
                key={item.id}
                data-name={item.name}
                data-slug={item.slug}
                data-id={item.id}
                onClick={tagClick}
                tabIndex='0'
                className={item.id == selectedTagIndex ? 'active' : ''}
              >
                <span>{item.name}</span>
              </li>
            );
          })}
        </ScrollableContainer>

        <VisionBoard
          ref={visionBoardRef}
          query={queryGQL}
          object={queryObject}
          variables={queryVariables}
          loadMoreCards={loadMoreCards}
          metadata={metadata}
        />
      </section>
    </>
  );
};

export default AllActivityComponent;
