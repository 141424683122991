import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import BackgroundImage from '../BackgroundImage';
import Button from 'react-bootstrap/Button';
import { Form, InputGroup } from 'react-bootstrap';

import { useReactiveVar } from '@apollo/client';
import { userLogInData } from '../../graphql/reactivities/user';

import { useQuery, useMutation } from '@apollo/client';
import { GET_FAVORITES } from '../../graphql/queries/profile.js';
import { UPDATE_FAVORITES } from '../../graphql/mutations/user.js';
import PageLoader from '../PageLoader';
import ErrorComponent from '../ErrorComponent';

const FavoritesScreen = ({ setup }) => {
  let history = useHistory();

  const userLogIn = useReactiveVar(userLogInData);
  const [data, setData] = useState(null);
  const [flavors, setFlavors] = useState([]);
  const [sizes, setSizes] = useState([]);
  const [platforms, setPlatforms] = useState([]);
  const [favoriteSize, setFavoriteSize] = useState('');
  const [favoritePlatform, setFavoritePlatform] = useState('');
  const [favoriteFlavor, setFavoriteFlavor] = useState('');
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(
    'Sorry, there was an error.'
  );
  let numErrors = 0;

  // UPDATE USER MUTATION
  const [updateUser] = useMutation(UPDATE_FAVORITES, {
    onCompleted: (data) => {
      if (data.updateDoritosLotbUser.success === true) {
        history.push('/setup/5');
      } else {
        numErrors++;
        if (numErrors > 1) {
          return <ErrorComponent />;
        } else {
          setHasError(true);
        }
      }
    },
    onError: (error) => {
      numErrors++;
      if (numErrors > 1) {
        return <ErrorComponent />;
      } else {
        setHasError(true);
      }
    },
  });

  const saveFavorites = (e) => {
    e.preventDefault();
    updateUser({
      variables: {
        id: userLogIn.id,
        tshirtsize: favoriteSize,
        flavor: favoriteFlavor,
        platform: favoritePlatform,
      },
    });
  };

  const { loading, error } = useQuery(GET_FAVORITES, {
    onCompleted: (data) => {
      setData(data);
    },
  });

  useEffect(() => {
    if (typeof data !== 'undefined' && data) {
      if (typeof data.getDoritosLotbFlavorListing !== 'undefined') {
        setFlavors(data.getDoritosLotbFlavorListing.edges);
      }
      if (typeof data.getDoritosLotbPlatformListing !== 'undefined') {
        setPlatforms(data.getDoritosLotbPlatformListing.edges);
      }
      if (typeof data.getDoritosLotbSizeListing !== 'undefined') {
        setSizes(data.getDoritosLotbSizeListing.edges);
      }
    }
  }, [data]);

  if (loading || !data) return <PageLoader />;
  if (error) return <ErrorComponent />;

  return (
    <>
      <div className='background-image-container'>
        <BackgroundImage data={setup.image.background} />
      </div>
      <div className='content favorites-screen'>
        <div className='content-holder'>
          <h2>{setup.metadata.header}</h2>
          {hasError && <p className='error'>{errorMessage}</p>}
          <Form>
            <Form.Row>
              <Form.Group controlId='platform'>
                <Form.Label>Favorite Platform</Form.Label>
                <InputGroup>
                  <Form.Control
                    name='platform'
                    required
                    as='select'
                    placeholder='Select Your Favorite'
                    defaultValue='-1'
                    onChange={(e) => setFavoritePlatform(e.target.value)}
                  >
                    <option disabled value='-1'>
                      Select Your Favorite
                    </option>
                    {platforms.map((platform, index) => (
                      <option key={index} value={platform.node.name}>
                        {platform.node.name}
                      </option>
                    ))}
                  </Form.Control>
                  <InputGroup.Append></InputGroup.Append>
                </InputGroup>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group controlId='flavor'>
                <Form.Label>Favorite Flavor</Form.Label>
                <InputGroup>
                  <Form.Control
                    name='flavor'
                    required
                    as='select'
                    placeholder='Select Flavor'
                    defaultValue='-1'
                    onChange={(e) => setFavoriteFlavor(e.target.value)}
                  >
                    <option disabled value='-1'>
                      Select Your Favorite
                    </option>
                    {flavors.map((flavor, index) => (
                      <option key={index} value={flavor.node.name}>
                        {flavor.node.name}
                      </option>
                    ))}
                  </Form.Control>
                  <InputGroup.Append></InputGroup.Append>
                </InputGroup>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group controlId='size'>
                <Form.Label>T-shirt Size</Form.Label>
                <InputGroup>
                  <Form.Control
                    name='size'
                    required
                    as='select'
                    placeholder='Select T-shirt Size'
                    defaultValue='-1'
                    onChange={(e) => setFavoriteSize(e.target.value)}
                  >
                    <option disabled value='-1'>
                      Select Your Size
                    </option>
                    {sizes.map((size, index) => (
                      <option key={index} value={size.node.name}>
                        {size.node.name}
                      </option>
                    ))}
                  </Form.Control>
                  <InputGroup.Append></InputGroup.Append>
                </InputGroup>
              </Form.Group>
            </Form.Row>

            <Button type='submit' variant='primary' onClick={saveFavorites}>
              save & continue
            </Button>

            {/* <div className='next-prev-buttons'>
              <span
                onClick={(e) => {
                  e.preventDefault();
                  history.goBack();
                }}
              >
                Back
              </span>
            </div> */}
          </Form>
        </div>
      </div>
    </>
  );
};

export default FavoritesScreen;
