import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { ReactComponent as SearchIcon } from '../assets/svg/search-icon.svg';
import { ReactComponent as ClearIcon } from '../assets/svg/x-icon.svg';

const SearchInput = forwardRef(
  ({ onSubmit, placeholder, searchCleared }, ref) => {
    const [searchText, setSearchText] = useState('');
    const [showClear, setShowClear] = useState(false);

    const search = (e) => {
      e.preventDefault();
      onSubmit(searchText);
    };

    useImperativeHandle(ref, () => ({
      triggerClearSearch() {
        setSearchText('');
        setShowClear(false);
      },
    }));

    const handleSearchChange = (e) => {
      setSearchText(e.target.value);
      setShowClear(e.target.value.length > 0 ? true : false);
    };

    const clear = () => {
      setSearchText('');
      setShowClear(false);
      searchCleared && searchCleared();
    };

    return (
      <>
        <form className='search-input' onSubmit={search}>
          <SearchIcon onClick={search} />
          <label id='search-label'>Search</label>
          <input
            aria-labelledby='search-label'
            type='text'
            placeholder={placeholder}
            value={searchText}
            onChange={handleSearchChange}
          ></input>
          {showClear && <ClearIcon className='search-clear' onClick={clear} />}
        </form>
      </>
    );
  }
);

export default SearchInput;
