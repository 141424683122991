import React from 'react';

const ItemsList = ({ items, onClicked, selectedItems, allowSelect = true }) => {
  return (
    <>
      <ul className={`items-list${allowSelect ? ' allow-select' : ''}`}>
        {items.map((item, index) => (
          <li
            data-name={item.node.name}
            key={index}
            onClick={(e) => {
              if (allowSelect) onClicked(e);
            }}
            className={
              allowSelect &&
              selectedItems.length &&
              selectedItems.findIndex((_item) => _item == item.node.name) >= 0
                ? 'active'
                : ''
            }
          >
            {`// ${item.node.name}`}
          </li>
        ))}
      </ul>
    </>
  );
};

export default ItemsList;
