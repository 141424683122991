import { makeVar, gql } from "@apollo/client";

export const userLoggedIn = makeVar(false);

export const GET_USER_LOGGED_IN = gql`
  query getUserLoggedIn{
    userLoggedIn @client
  }
`

export const userLogInData = makeVar(null);

export const GET_USER_LOG_IN_DATA = gql`
  query getUserLogInData{
    userLogInData @client
  }
`

export const userData = makeVar(null);

export const GET_USER_DATA = gql`
  query getUserData{
    userData @client
  }
`
