import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import VisionBoardCard from './VisionBoardCard';
import ComponentLoader from './ComponentLoader';
import { useQuery } from '@apollo/client';
import { ReactComponent as HollowChip } from '../assets/svg/hollow-chip.svg';
import { ReactComponent as FullChip } from '../assets/svg/full-chip.svg';

const VisionBoard = forwardRef((props, ref) => {
  const redHollowChipRef = useRef(null);
  const greenHollowChipRef = useRef(null);
  const fullChipRef = useRef(null);

  const [reset, setReset] = useState(true);

  const { data, loading, error } = useQuery(props.query, {
    variables: props.variables,
  });

  const [loadMore, setLoadMore] = useState(true);
  const [cards, setCards] = useState(props.cards);

  useImperativeHandle(ref, () => ({
    triggerReset() {
      setReset(true);
    },
  }));

  useEffect(() => {
    if (data) {
      let count = 0;
      const total = data[props.object].totalCount;
      if (!cards || props.variables.after == 0) {
        setCards(data[props.object].edges);
        count = data[props.object].edges.length;
      } else if (reset) {
        setCards(data[props.object].edges);
        count = data[props.object].edges.length;
      } else {
        const newCards = cards.concat(data[props.object].edges);
        setCards(newCards);
        count = newCards.length;
      }
      setReset(false);

      if (count < total) {
        setLoadMore(true);
      } else {
        setLoadMore(false);
      }
    }
  }, [data]);

  if (loading) return <ComponentLoader />;
  
  return (
    <>
      <section
        className={`vision-board ${props.className ? props.className : ''}`}
      >
        {cards && !cards.length && (
          <p className='no-results'>{props.metadata.noResults}</p>
        )}
        {error && <p className='no-results'>ERROR</p>}

        {!cards || reset ? (
          <ComponentLoader className='transparent' />
        ) : (
          <div className='card-holder'>
            {cards.filter(card => card.__typename != 'DoritosLotbLookupEdge' || card.node.lookupobject).map((card) => (
              <VisionBoardCard key={card.node.id} data={card.node} />
            ))}
          </div>
        )}

        <div className='vision-board-background'>
          <HollowChip className='green-hollow-chip' ref={greenHollowChipRef} />
          <HollowChip className='red-hollow-chip' ref={redHollowChipRef} />
          <FullChip className='full-chip' ref={fullChipRef} />
        </div>
      </section>

      {loadMore && (
        <p
          className='load-more'
          style={{ zIndex: '1' }}
          onClick={props.loadMoreCards}
        >
          Load More
        </p>
      )}
    </>
  );
});

export default VisionBoard;
