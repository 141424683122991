import React, {
  forwardRef,
  useRef,
  useState,
  useEffect,
  useImperativeHandle,
} from 'react';
import { Form, Col, InputGroup } from 'react-bootstrap';

import { states } from '../../assets/data/states.json';

const EditUserInfoAddress = forwardRef(
  ({ userInfo, updateUserInfo, setAddressFormValid, resetErrors }, ref) => {
    const formRef = useRef(null);

    const [validated, setValidated] = useState(false);
    const [hasError, setHasError] = useState(false);

    useImperativeHandle(ref, () => ({
      triggerFormSubmit() {
        submitForm();
      },
    }));

    useEffect(() => {
      formRef.current.addEventListener('click', () => {
        setHasError(false);
        resetErrors();
      });
    }, []);

    const submitForm = () => {
      const form = formRef.current;
      if (form.checkValidity() === false) {
        // not valid
        setHasError(true);
      } else {
        // valid
        setHasError(false);
        setAddressFormValid(true);
      }
      setValidated(true);
    };

    return (
      <div className='edit-user-info-address'>
        <Form
          noValidate
          validated={validated}
          onSubmit={submitForm}
          ref={formRef}
        >
          <Form.Row>
            {/* first name */}
            <Form.Group as={Col} xs='6' controlId='firstname'>
              <Form.Label>first name</Form.Label>
              <Form.Control
                name='firstname'
                required
                type='text'
                defaultValue={userInfo.firstname ? userInfo.firstname : ''}
                onChange={(e) => updateUserInfo({ firstname: e.target.value })}
              />
            </Form.Group>
            {/* last name */}
            <Form.Group as={Col} xs='6' controlId='lastname'>
              <Form.Label>last name</Form.Label>
              <Form.Control
                name='lastname'
                required
                type='text'
                defaultValue={userInfo.lastname ? userInfo.lastname : ''}
                onChange={(e) => updateUserInfo({ lastname: e.target.value })}
              />
            </Form.Group>
          </Form.Row>

          {/* address */}
          <Form.Row>
            <Form.Group as={Col} controlId='address'>
              <Form.Label>address</Form.Label>
              <Form.Control
                name='address'
                required
                type='text'
                defaultValue={userInfo.address ? userInfo.address : ''}
                onChange={(e) => updateUserInfo({ address: e.target.value })}
              />
            </Form.Group>
          </Form.Row>

          {/* city */}
          <Form.Row>
            <Form.Group as={Col} controlId='city'>
              <Form.Label>city</Form.Label>
              <Form.Control
                name='city'
                required
                type='text'
                defaultValue={userInfo.city ? userInfo.city : ''}
                onChange={(e) => updateUserInfo({ city: e.target.value })}
              />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            {/* state */}
            <Form.Group as={Col} xs='8' controlId='state'>
              <Form.Label>state</Form.Label>
              <InputGroup>
                <Form.Control
                  name='state'
                  required
                  as='select'
                  defaultValue={userInfo.state.toUpperCase()}
                  placeholder='Select State'
                  onChange={(e) => updateUserInfo({ state: e.target.value })}
                >
                  {states.map((state, index) => (
                    <option key={index} value={state.abbreviation}>
                      {state.name}
                    </option>
                  ))}
                </Form.Control>
                <InputGroup.Append></InputGroup.Append>
              </InputGroup>
            </Form.Group>
            {/* zip */}
            <Form.Group as={Col} xs='4' controlId='zip'>
              <Form.Label>zip</Form.Label>
              <Form.Control
                name='zip'
                required
                type='number'
                defaultValue={userInfo.zip ? userInfo.zip : ''}
                onChange={(e) => {
                  e.target.value = Math.max(0, parseInt(e.target.value))
                    .toString()
                    .slice(0, 5);
                  updateUserInfo({ zip: e.target.value });
                }}
              />
            </Form.Group>
          </Form.Row>
        </Form>
      </div>
    );
  }
);
export default EditUserInfoAddress;
