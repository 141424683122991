import React, { useRef, useState } from 'react';
import ScrollableContainer from '../ScrollableContainer';
import { useReactiveVar } from '@apollo/client';
import { userLogInData } from '../../graphql/reactivities/user';
import { getUserTier } from '../../Helpers';

const TabComponent = ({ data }) => {
  let tabs = [];

  const userLogIn = useReactiveVar(userLogInData);
  const formatAttachments = (items) => {
    let content = '';
    items.forEach((item) => {
      content += `
        <p>
          <a class="submission-attachment" href="${item.attachment.fullpath}" rel="noreferrer" target="_blank" title="${item.title} - Opens in a new tab">
            ${item.title}
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
              <path d="M17 17H3V3h5V1H3a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-5h-2z" />
              <path d="M19 1h-8l3.29 3.29-5.73 5.73 1.42 1.42 5.73-5.73L19 9V1z" />
            </svg>
          </a>
        </p>`;
    });
    return content;
  };

  const TabItems = ({ items }) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const scrollableContainerRef = useRef(null);

    const onItemClicked = (e) => {
      const id = e.target.dataset.id;
      scrollableContainerRef.current.elementClicked(e.target);
      setActiveIndex(id);
    };

    return (
      <>
        <ScrollableContainer ref={scrollableContainerRef} scrollerColor='green'>
          {items.map((item, index) => (
            <li
              key={index}
              data-id={index}
              onClick={onItemClicked}
              tabIndex='0'
              className={index == activeIndex ? 'active' : ''}
            >
              <span>{item.title}</span>
            </li>
          ))}
        </ScrollableContainer>
        {items.map((item, index) => (
          <div
            key={index}
            data-id={index}
            className={index == activeIndex ? 'tab-panel active' : 'tab-panel'}
          >
            {item.tabItems && <TabItems items={item.tabItems} />}
            {item.textItems && renderTextItems(item.textItems)}
            {item.content && (
              <div
                className='tab-panel-content'
                dangerouslySetInnerHTML={{ __html: item.content }}
              ></div>
            )}
          </div>
        ))}
      </>
    );
  };

  const renderTextItems = (items) =>
    items.map((item, index) => <p key={index}>{item}</p>);

  const formatPrizing = (title, type, data) => {
    let tier = getUserTier(userLogIn);
    let prizing = { title: title };

    data.every((item) => {
      let prizes = JSON.parse(item[`${type}_prizes`]);
      let textItems = [];
      prizes.forEach((prize) => {
        if (prize[0] != 'Title') {
          textItems.push(`${prize[0]}: ${prize[1]}`);
        }
      });

      prizing.textItems = textItems;
      if (item[`${type}_userlevel`]) {
        if (item[`${type}_userlevel`].tier == tier) return false;
      }
      return true;
    });

    return prizing;
  };

  if (data.assignment) {
    tabs.push({
      title: 'Assignment',
      content: data.assignment,
    });
  }
  if (
    data.cash.length > 0 ||
    data.products.length > 0 ||
    data.experiences.length > 0
  ) {
    let tabItems = [];
    if (data.cash.length > 0) {
      tabItems.push(formatPrizing('Cash', 'cash', data.cash));
    }
    if (data.products.length > 0) {
      tabItems.push(formatPrizing('Products', 'products', data.products));
    }
    if (data.experiences.length > 0) {
      tabItems.push(
        formatPrizing('Experiences', 'experiences', data.experiences)
      );
    }
    tabs.push({
      title: 'Prizing',
      tabItems: tabItems,
    });
  }
  if (data.objective) {
    tabs.push({
      title: 'Objective',
      content: data.objective,
    });
  }
  if (data.deliverables) {
    tabs.push({
      title: 'Deliverables',
      content: data.deliverables,
    });
  }
  if (data.attachments) {
    tabs.push({
      title: 'Attachments',
      content: formatAttachments(data.attachments),
    });
  }

  return <div className='tab-component'>{<TabItems items={tabs} />}</div>;
};

export default TabComponent;
