import { gql } from '@apollo/client';

export const SUBMISSION_ADD = gql`
  mutation addSubmission($uid: Int!, $cid: Int!) {
    createDoritosLotbSubmission(
      key: "submission"
      path: "/"
      input: {
        user: { id: $uid, type: "object" }
        challenge: { id: $cid, type: "object" }
      }
    ) {
      success
      output {
        id
      }
    }
  }
`;

export const SUBMISSION_UPDATE = gql`
  mutation updateSubmission(
    $id: Int!
    $title: String!
    $summary: String!
    $prizetype: String!
    $tags: [String]!
    $attachments: [ElementDescriptorInput]!
  ) {
    updateDoritosLotbSubmission(
      id: $id
      input: {
        title: $title
        summary: $summary
        prizetype: $prizetype
        tags: $tags
        attachments: $attachments
      }
    ) {
      success
      output {
        id
        title
        summary
        tags
        prizetype
        attachments {
          ... on object_DoritosLotbSubmissionAttachment {
            id
            attachmenttype
            attachment {
              ... on asset {
                fullpath
                type
              }
            }
          }
        }
      }
    }
  }
`;
