import { gql } from '@apollo/client';

export const USER_LOGIN = gql`
  query UserLogin($token: String!) {
    userLogin(token: $token) {
      token
      status
      id
      username
      avatar
      new_user
      setup
      notifications {
        nid
        title
        message
      }
    }
  }
`;

export const USER_LOGOUT = gql`
  {
    userLogout(username: "logout") {
      status
    }
  }
`;

export const GET_USER_BY_USERNAME = gql`
  query getDoritosLotbUserListing($filter: String!) {
    getDoritosLotbUserListing(defaultLanguage: "en", filter: $filter) {
      totalCount
      edges {
        node {
          id
          username
          avatar {
            ... on object_DoritosLotbAvatar {
              id
              name
              image {
                fullpath
              }
            }
          }
          profileheader {
            ... on object_DoritosLotbProfileHeader {
              id
              name
              image {
                fullpath
              }
            }
          }
          email
          firstname
          lastname
          address
          city
          state
          zip
          interests
          tier
          points
          prizes
          twitter
          instagram
          youtube
          behance
          tiktok
          linkedin
          facebook
          snapchat
          twitch
          tshirtsize
          favoriteflavor
          favoriteplatform
        }
      }
    }
  }
`;

export const GET_USER = gql`
  query getDoritosLotbUser($id: Int!) {
    getDoritosLotbUser(id: $id) {
      id
      username
      avatar {
        ... on object_DoritosLotbAvatar {
          id
          name
          image {
            fullpath
          }
        }
      }
      profileheader {
        ... on object_DoritosLotbProfileHeader {
          id
          name
          image {
            fullpath
          }
        }
      }
      optin
      email
      firstname
      lastname
      address
      city
      state
      zip
      interests
      tier
      points
      twitter
      instagram
      youtube
      behance
      tiktok
      linkedin
      facebook
      snapchat
      twitch
      tshirtsize
      favoriteflavor
      favoriteplatform
    }
  }
`;

export const GET_USER_STATS_LOGGED_OUT = gql`
  query getDoritosLotbUserListing($filter: String!) {
    getDoritosLotbUserListing(defaultLanguage: "en", filter: $filter) {
      totalCount
      edges {
        node {
          id
          username
          avatar {
            ... on object_DoritosLotbAvatar {
              id
              name
              image {
                fullpath
              }
            }
          }
          profileheader {
            ... on object_DoritosLotbProfileHeader {
              id
              name
              image {
                fullpath
              }
            }
          }
          email
          firstname
          lastname
          address
          city
          state
          zip
          interests
          tier
          points
          prizes
          twitter
          instagram
          youtube
          behance
          tiktok
          linkedin
          facebook
          snapchat
          twitch
          tshirtsize
          favoriteflavor
          favoriteplatform
        }
      }
    }
    getDoritosLotbWinnerListing(defaultLanguage: "en", filter: $filter) {
      totalCount
      edges {
        node {
          id
          title
        }
      }
    }
  }
`;

export const GET_USER_STATS_LOGGED_IN = gql`
  query getDoritosLotbUserListing($filter: String!, $metaFilter: String!) {
    getDoritosLotbUserListing(defaultLanguage: "en", filter: $filter) {
      totalCount
      edges {
        node {
          id
          username
          avatar {
            ... on object_DoritosLotbAvatar {
              id
              name
              image {
                fullpath
              }
            }
          }
          profileheader {
            ... on object_DoritosLotbProfileHeader {
              id
              name
              image {
                fullpath
              }
            }
          }
          email
          firstname
          lastname
          address
          city
          state
          zip
          interests
          tier
          points
          prizes
          twitter
          instagram
          youtube
          behance
          tiktok
          linkedin
          facebook
          snapchat
          twitch
          tshirtsize
          favoriteflavor
          favoriteplatform
        }
      }
    }
    getDoritosLotbWinnerListing(defaultLanguage: "en", filter: $filter) {
      totalCount
      edges {
        node {
          id
        }
      }
    }
    getDoritosLotbMetaDataListing(filter: $metaFilter) {
      totalCount
      edges {
        node {
          id
          uid
          oid
          activity
        }
      }
    }
  }
`;
