import React from 'react';
import GeneralPage from '../components/GeneralPage';
import { Helmet } from 'react-helmet';
import PageLoader from '../components/PageLoader';

import { GET_CONTENT } from '../graphql/queries/content.js';
import { useQuery } from '@apollo/client';

const Terms = () => {
  const { data, loading, error } = useQuery(GET_CONTENT, {
    variables: { filter: `{"slug": "terms-of-use"}` },
  });

  if (loading) return <PageLoader />;
  if (error) return <p>ERROR</p>;
  if (!data || !data.getDoritosLotbContentListing.edges[0])
    return <PageLoader />;

  const _data = data.getDoritosLotbContentListing.edges[0].node;

  return (
    <>
      <Helmet>
        <title> - Terms</title>
      </Helmet>
      <GeneralPage>
        <h1>{_data.title}</h1>
        <div dangerouslySetInnerHTML={{ __html: _data.content }}></div>
      </GeneralPage>
    </>
  );
};

export default Terms;
