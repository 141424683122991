import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import BackgroundImage from '../BackgroundImage';
import ScrollableContainer from '../ScrollableContainer';
import ImageComponent from '../Image';
import PageLoader from '../PageLoader';
import { useReactiveVar } from '@apollo/client';
import { userLogInData } from '../../graphql/reactivities/user';

import { useQuery, useMutation } from '@apollo/client';
import { GET_HEADERS } from '../../graphql/queries/profile.js';
import { UPDATE_HEADER } from '../../graphql/mutations/user.js';
import ErrorComponent from '../ErrorComponent';

const HeaderScreen = ({ setup }) => {
  let history = useHistory();

  const userLogIn = useReactiveVar(userLogInData);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(
    'Sorry, there was an error.'
  );
  let numErrors = 0;

  // UPDATE USER MUTATION
  const [updateUser] = useMutation(UPDATE_HEADER, {
    onCompleted: (data) => {
      if (data.updateDoritosLotbUser.success === true) {
        history.push('/setup/4');
      } else {
        numErrors++;
        if (numErrors > 1) {
          return <ErrorComponent />;
        } else {
          setHasError(true);
        }
      }
    },
    onError: (error) => {
      numErrors++;
      if (numErrors > 1) {
        return <ErrorComponent />;
      } else {
        setHasError(true);
      }
    },
  });

  const { loading, error, data } = useQuery(GET_HEADERS);

  if (loading || !data) return <PageLoader />;
  if (error || !data.getDoritosLotbProfileHeaderListing.edges[0])
    return <ErrorComponent />;

  const headerSelected = (e) => {
    const oid = e.target.dataset.id;
    updateUser({ variables: { id: userLogIn.id, oid: oid } });
  };

  return (
    <>
      <div className='background-image-container'>
        <BackgroundImage data={setup.image.background} />
      </div>
      <div className='content profile-header-screen'>
        <div className='content-holder'>
          <h2>{setup.metadata.header}</h2>

          {hasError && <p className='error'>{errorMessage}</p>}

          <ScrollableContainer scrollerColor={'white'}>
            {data.getDoritosLotbProfileHeaderListing.edges.map((item) => (
              <li
                key={item.node.id}
                data-name={item.node.name ? item.node.name : item.node.alt}
                data-id={item.node.id}
                onClick={headerSelected}
                tabIndex='0'
                className=''
              >
                <ImageComponent
                  url={item.node.image.fullpath}
                  alt={item.node.name}
                />
              </li>
            ))}
          </ScrollableContainer>
        </div>
      </div>
    </>
  );
};

export default HeaderScreen;
