import { gql } from '@apollo/client';

export const GET_WINNERS_PAGE = gql`
query getWinnersPage(
  $filter: String!
) {
  getDoritosLotbContentListing (
    defaultLanguage: "en"
    filter: $filter
  ) {
    edges {
      node {
        slug
        title
        tag
        contentType
        content
        metadata {
          key
          value
        }
        image {
          ... on object_DoritosLotbImage {
            title
            slug
            alt
            image {
              fullpath
            }
            width
            height
            mobileImage {
              fullpath
            }
            mobileWidth
            mobileHeight
          }
        }
        video {
          ... on asset {
            fullpath
          }
        }
      }
    }
  }
  getDoritosLotbChallengeTypeListing (
    defaultLanguage: "en"
  ) {
    edges {
      node {
        id
        name
      }
    }
  }
}
`;

export const GET_WINNER = gql`
query getWinner(
  $id: Int!
  $uid: Int!
  $oid: Int!
) {
  getDoritosLotbWinner(id: $id) {
    id
    slug
    title
    description
    featured
    prize
    place
    likes
    user {
      ... on object_DoritosLotbUser {
        id
        username
        avatar {
          ... on object_DoritosLotbAvatar {
            image {
              fullpath
            }
          }
        }
      }
    }
    challenge {
      ... on object_DoritosLotbChallenge {
        id
        title
        slug
      }
    }
    category
    tags
    image {
      fullpath
    }
    thumbnail {
      fullpath
    }
    video {
      ... on asset {
      	fullpath
      }
    }
    liked
  }
  getLike (
    uid: $uid
    oid: $oid
  ){
    liked
  }
}
`;
