import React, { useRef, useState, useEffect } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { Col, Container, Row } from 'react-bootstrap';
import BackgroundImage from '../BackgroundImage';
import EditUserInfoAddress from './EditUserInfoAddress';
import EditUserSocials from './EditUserSocials';
import Form from 'react-bootstrap/Form';
import EditUserInterests from './EditUserInterests';
import Button from 'react-bootstrap/Button';
import { useReactiveVar } from '@apollo/client';
import { userLogInData } from '../../graphql/reactivities/user';
import { GET_USER } from '../../graphql/queries/user.js';
import { UPDATE_USER } from '../../graphql/mutations/user.js';
import { useQuery, useMutation } from '@apollo/client';

import AvatarSelector from '../AvatarSelector';
import ProfileHeaderSelector from '../ProfileHeaderSelector';
import PageLoader from '../PageLoader';
import ErrorComponent from '../ErrorComponent';

const EditUserComponent = ({ pageData, username }) => {
  let history = useHistory();

  const userLogIn = useReactiveVar(userLogInData);

  const addressFormRef = useRef(null);
  const socialsFormRef = useRef(null);

  const [pageLoading, setPageLoading] = useState(true);
  const [optin, setOptin] = useState(null);
  const [userInfo, setUserInfo] = useState(null);
  const [userSocials, setUserSocials] = useState(null);
  const [selectedAvatarId, setSelectedAvatarId] = useState(0);
  const [selectedHeaderId, setSelectedHeaderId] = useState(0);
  const [selectedInterests, updateSelectedInterests] = useState([]);
  const [addressFormValid, setAddressFormValid] = useState(false);
  const [socialsFormValid, setSocialsFormValid] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(
    'Sorry, there was an error saving the user data.'
  );

  useEffect(() => {
    // if both socials and address forms are valid, send it
    if (!addressFormValid || !socialsFormValid) return;
    const dataToSave = {
      id,
      ...userInfo,
      avatarid: selectedAvatarId,
      ...userSocials,
      headerid: selectedHeaderId,
      interests: selectedInterests,
      optin,
    };
    updateUser({ variables: dataToSave });
  }, [addressFormValid, socialsFormValid]);

  let id;
  if (!userLogIn) {
    // user is not logged in, sending to home
    history.push('/');
  } else if (userLogIn.username !== username) {
    // this is not the user's profile
    history.push(`/users/${username}`);
  } else {
    id = userLogIn.id;
  }

  const [updateUser] = useMutation(UPDATE_USER, {
    onCompleted: (data) => {
      if (data.updateDoritosLotbUser.success === true) {
        history.push(`/users/${userLogIn.username}`);
      } else {
        setHasError(true);
      }
    },
    onError: (error) => {
      setHasError(true);
    },
    update: (cache, data) => {
      // update cache
    },
  });

  const { loading, error } = useQuery(GET_USER, {
    fetchPolicy: 'no-cache',
    variables: { id: id },
    onCompleted: (_data) => {
      setUserInfo({
        //username: _data.getDoritosLotbUser.username,
        firstname: _data.getDoritosLotbUser.firstname,
        lastname: _data.getDoritosLotbUser.lastname,
        address: _data.getDoritosLotbUser.address,
        city: _data.getDoritosLotbUser.city,
        state: _data.getDoritosLotbUser.state,
        zip: _data.getDoritosLotbUser.zip,
      });
      setUserSocials({
        facebook: _data.getDoritosLotbUser.facebook,
        behance: _data.getDoritosLotbUser.behance,
        instagram: _data.getDoritosLotbUser.instagram,
        linkedin: _data.getDoritosLotbUser.linkedin,
        twitter: _data.getDoritosLotbUser.twitter,
        youtube: _data.getDoritosLotbUser.youtube,
        twitch: _data.getDoritosLotbUser.twitch,
        tiktok: _data.getDoritosLotbUser.tiktok,
        snapchat: _data.getDoritosLotbUser.snapchat,
      });
      setSelectedAvatarId(
        _data.getDoritosLotbUser.avatar ? _data.getDoritosLotbUser.avatar.id : 0
      );
      setSelectedHeaderId(
        _data.getDoritosLotbUser.profileheader
          ? _data.getDoritosLotbUser.profileheader.id
          : 0
      );
      setOptin(_data.getDoritosLotbUser.optin);
      if (
        _data.getDoritosLotbUser.interests &&
        _data.getDoritosLotbUser.interests.length
      )
        updateSelectedInterests([..._data.getDoritosLotbUser.interests]);
      setPageLoading(false);
    },
  });

  if (loading || pageLoading) return <PageLoader />;
  if (error) return <ErrorComponent />;

  const updateUserInfo = (data) => {
    setUserInfo({ ...userInfo, ...data });
  };

  const updateUserSocials = (data) => {
    setUserSocials({ ...userSocials, ...data });
  };

  const addRemoveInterest = (name) => {
    if (selectedInterests.findIndex((interest) => interest === name) >= 0) {
      updateSelectedInterests((list) => list.filter((item) => item !== name));
    } else {
      updateSelectedInterests((list) => [...list, name]);
    }
  };

  const onAvatarClicked = (id) => {
    setSelectedAvatarId(id);
  };

  const onHeaderClicked = (id) => {
    setSelectedHeaderId(id);
  };
  const onSave = () => {
    setAddressFormValid(false);
    setSocialsFormValid(false);
    addressFormRef.current.triggerFormSubmit();
    socialsFormRef.current.triggerFormSubmit();
  };

  const onCancel = () => {
    // go back?
    history.push('/users/' + username);
  };

  const resetErrors = () => {
    setHasError(false);
  };

  return (
    <section className='edit-user'>
      <div className='background-image-container'>
        {/* <div className='background-image-cover'></div> */}
        <BackgroundImage data={pageData.image.background} />
      </div>
      <Container>
        <Row>
          <Col lg='6'>
            <h1>
              {pageData.metadata.header} - {username}
            </h1>
            {hasError && <p className='error'>{errorMessage}</p>}
            <EditUserInfoAddress
              resetErrors={resetErrors}
              userInfo={userInfo}
              updateUserInfo={updateUserInfo}
              ref={addressFormRef}
              setAddressFormValid={setAddressFormValid}
            />
            <div className='edit-user-opt-ins'>
              <Form>
                <Form.Row>
                  <Form.Group>
                    <input
                      id='optin'
                      className='checkbox'
                      tabIndex='0'
                      type='checkbox'
                      checked={optin}
                      onChange={() => setOptin(!optin)}
                    />
                    <span className='checkbox-checkmark'></span>
                    <label htmlFor='optin'>
                      {pageData.metadata.optinNotifications}
                    </label>
                  </Form.Group>
                </Form.Row>
              </Form>
            </div>
            <EditUserSocials
              ref={socialsFormRef}
              setSocialsFormValid={setSocialsFormValid}
              userSocials={userSocials}
              updateUserSocials={updateUserSocials}
            />
          </Col>

          <Col className='right-side' lg='6'>
            <div className='edit-user-avatar'>
              <h2>Avatar</h2>

              <AvatarSelector
                avatarSelected={onAvatarClicked}
                activeId={selectedAvatarId}
              />
            </div>

            <div className='edit-user-header'>
              <h2>Profile Header Image</h2>

              <ProfileHeaderSelector
                activeId={selectedHeaderId}
                headerSelected={onHeaderClicked}
              />
            </div>

            <div className='edit-user-interests'>
              <h2>Interests</h2>

              <EditUserInterests
                selectedInterests={selectedInterests}
                addRemoveInterest={addRemoveInterest}
              />
            </div>

            <div className='edit-user-buttons'>
              <Button tabIndex='0' variant='primary' onClick={onSave}>
                save
              </Button>
              <Button tabIndex='0' variant='primary' onClick={onCancel}>
                cancel
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default EditUserComponent;
