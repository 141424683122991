import React from 'react';
import GeneralPage from '../components/GeneralPage';

import data from '../assets/data/privacy.json';
import { Helmet } from 'react-helmet';

const Privacy = () => {
  return (
    <>
      <Helmet>
        <title> - Privacy</title>
      </Helmet>
      <GeneralPage>
        <h1>{data.heading}</h1>
        <div dangerouslySetInnerHTML={{ __html: data.content }}></div>
      </GeneralPage>
    </>
  );
};

export default Privacy;
