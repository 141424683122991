import { useLazyQuery } from '@apollo/client';
import React from 'react';
import { useHistory } from 'react-router';
import { USER_LOGOUT } from '../graphql/queries/user';
import { userLoggedIn, userLogInData } from '../graphql/reactivities/user';

import logo from '../assets/svg/lotb-logo.svg';
import { Button } from 'react-bootstrap';

const ErrorComponent = () => {
  const history = useHistory();
  const [logoutUser] = useLazyQuery(USER_LOGOUT, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => handleData(data),
  });

  function handleData(data) {
    if (data.userLogout && data.userLogout.status === 'Success') {
      userLoggedIn(false);
      userLogInData(null);
      localStorage.clear();
      history.push('/');
    }
  }

  const tryAgain = () => {
    logoutUser();
  };

  return (
    <div className='error-component'>
      <img alt='logo' src={logo} />
      <h1>Oops, there was an error.</h1>
      <Button variant='primary' onClick={tryAgain}>
        Try Again
      </Button>
    </div>
  );
};

export default ErrorComponent;
