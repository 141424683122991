import { ApolloClient, createHttpLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import cache from './cache';

const API_URL = process.env.REACT_APP_API_URL;
const API_KEY = process.env.REACT_APP_API_KEY;

const httpLink = createHttpLink({
  uri: `${API_URL}?apikey=${API_KEY}`,
  credentials: 'include',
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) => {
      // console.log(
      //   `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      // );
      if (message == 'Expired token') {
        localStorage.clear();
        window.location.reload();
      }
    });
  // if (networkError) console.log(`[Network error]: ${networkError}`);
});

const client = new ApolloClient({
  link: errorLink.concat(httpLink),
  cache,
  // connectToDevTools: true,
});

export default client;
