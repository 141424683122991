import React from 'react';
import { ReactComponent as HollowChip } from '../../assets/svg/hollow-chip.svg';

const UserInterests = ({ interests }) => {
  return (
    <>
      <div className='user-interests'>
        <div className='graphics'>
          <HollowChip className='blue-hollow-chip-1' />
          <HollowChip className='red-hollow-chip' />
          <HollowChip className='yellow-hollow-chip' />
          <HollowChip className='green-hollow-chip' />
          <HollowChip className='blue-hollow-chip-2' />
          <div className='panel left-side'></div>
          <div className='panel right-side'></div>
          <div className='box left-side'></div>
          <div className='box right-side'></div>
        </div>
        <div className='content'>
          <h3>Interests</h3>
          {interests && interests.length ? (
            <ul className='items-list'>
              {interests.map((item, index) => (
                <li key={index}>{`// ${item}`}</li>
              ))}
            </ul>
          ) : (
            <p>No Interests Selected</p>
          )}
        </div>
      </div>
    </>
  );
};

export default UserInterests;
