import React from 'react';

import { ReactComponent as HollowChip } from '../assets/svg/hollow-chip.svg';

const GeneralPage = ({ children }) => {
  return (
    <section className='general-page container'>
      <div className='graphics'>
        <HollowChip className='blue-hollow-chip' />
      </div>
      <div className='content'>{children}</div>
    </section>
  );
};

export default GeneralPage;
