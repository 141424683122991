import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import BackgroundImage from '../BackgroundImage';
import AvatarSelector from '../AvatarSelector';
import PageLoader from '../PageLoader';

import { useReactiveVar } from '@apollo/client';
import { userLogInData } from '../../graphql/reactivities/user';

import { useQuery, useMutation } from '@apollo/client';
import { GET_AVATARS } from '../../graphql/queries/profile.js';
import { UPDATE_AVATAR } from '../../graphql/mutations/user.js';
import ErrorComponent from '../ErrorComponent';

const AvatarScreen = ({ setup }) => {
  let history = useHistory();

  const userLogIn = useReactiveVar(userLogInData);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(
    'Sorry, there was an error loading avatars.'
  );
  let numErrors = 0;

  // UPDATE USER MUTATION
  const [updateUser] = useMutation(UPDATE_AVATAR, {
    onCompleted: (data) => {
      if (data.updateDoritosLotbUser.success === true) {
        history.push('/setup/3');
      } else {
        numErrors++;
        if (numErrors > 1) {
          return <ErrorComponent />;
        } else {
          setHasError(true);
        }
      }
    },
    onError: (error) => {
      numErrors++;
      if (numErrors > 1) {
        return <ErrorComponent />;
      } else {
        setHasError(true);
      }
    },
  });

  const { loading, error, data } = useQuery(GET_AVATARS);

  if (loading || !data) return <PageLoader />;
  if (error || !data.getDoritosLotbAvatarListing.edges[0])
    return <ErrorComponent />;

  const avatarSelected = (id) => {
    const oid = id;
    updateUser({ variables: { id: userLogIn.id, oid: oid } });
  };

  return (
    <>
      <div className='background-image-container'>
        <BackgroundImage data={setup.image.background} />
      </div>
      <div className='content avatar-screen'>
        <div className='content-holder'>
          <h2>{setup.metadata.header}</h2>

          {hasError && <p className='error'>{errorMessage}</p>}

          <AvatarSelector
            scrollerColor={'white'}
            avatarSelected={avatarSelected}
          />
        </div>
      </div>
    </>
  );
};

export default AvatarScreen;
