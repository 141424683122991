import React, { useState, useEffect, useRef } from 'react';
import VisionBoard from '../VisionBoard';
import ScrollableContainer from '../ScrollableContainer';
import {
  GET_VISION_BOARD,
  GET_VISION_BOARD_CHALLENGES_BY_ID,
} from '../../graphql/queries/visionboard.js';
import { useReactiveVar } from '@apollo/client';
import { theCardCount } from '../../graphql/reactivities/site';

const UserChallenges = ({ uid, wins, submissions, yes, maybe, no, isMe }) => {
  const cardCount = useReactiveVar(theCardCount);
  const [queryGQL, setQueryGQL] = useState(GET_VISION_BOARD);
  const [queryObject, setQueryObject] = useState('getDoritosLotbLookupListing');
  const [queryVariables, setQueryVariables] = useState({
    filter: `{"uid":${uid}}`,
    sort: 'sortdate',
    order: 'desc',
    first: cardCount,
    after: 0,
  });

  useEffect(() => {
    if (tags.length > 0) {
      filterByTag(tags[0].slug);
    }
  }, []);

  const [selectedTagIndex, setSelectedTagIndex] = useState(0);
  const scrollableContainerRef = useRef(null);
  const visionBoardRef = useRef(null);

  let tags = [];
  let tagIndex = 0;
  if (wins > 0) {
    tags.push({ id: tagIndex, name: 'winners', slug: 'winners' });
    tagIndex++;
  }
  if (isMe) {
    if (submissions.length > 0) {
      tags.push({ id: tagIndex, name: 'submitted', slug: 'submitted' });
      tagIndex++;
    }
    if (yes.length > 0) {
      tags.push({ id: tagIndex, name: "i'm on it", slug: 'yes' });
      tagIndex++;
    }
    if (maybe.length > 0) {
      tags.push({ id: tagIndex, name: 'maybe', slug: 'maybe' });
      tagIndex++;
    }
    if (no.length > 0) {
      tags.push({ id: tagIndex, name: 'not interested', slug: 'nope' });
      tagIndex++;
    }
  }

  if (tags.length == 0) return <></>;

  function filterByTag(tag) {
    if (tag == 'winners') {
      setQueryGQL(GET_VISION_BOARD);
      setQueryObject('getDoritosLotbLookupListing');
      setQueryVariables({
        filter: `{"uid":${uid}}`,
        sort: 'sortdate',
        order: 'desc',
        first: cardCount,
        after: 0,
      });
    } else if (tag == 'submitted') {
      setQueryGQL(GET_VISION_BOARD_CHALLENGES_BY_ID);
      setQueryObject('getDoritosLotbChallengeListing');
      setQueryVariables({
        ids: [...new Set(submissions)].join(','),
        sort: 'sortdate',
        order: 'desc',
        first: cardCount,
        after: 0,
      });
    } else if (tag == 'yes') {
      setQueryGQL(GET_VISION_BOARD_CHALLENGES_BY_ID);
      setQueryObject('getDoritosLotbChallengeListing');
      setQueryVariables({
        ids: [...new Set(yes)].join(','),
        sort: 'sortdate',
        order: 'desc',
        first: cardCount,
        after: 0,
      });
    } else if (tag == 'maybe') {
      setQueryGQL(GET_VISION_BOARD_CHALLENGES_BY_ID);
      setQueryObject('getDoritosLotbChallengeListing');
      setQueryVariables({
        ids: [...new Set(maybe)].join(','),
        sort: 'sortdate',
        order: 'desc',
        first: cardCount,
        after: 0,
      });
    } else if (tag == 'no') {
      setQueryGQL(GET_VISION_BOARD_CHALLENGES_BY_ID);
      setQueryObject('getDoritosLotbChallengeListing');
      setQueryVariables({
        ids: [...new Set(no)].join(','),
        sort: 'sortdate',
        order: 'desc',
        first: cardCount,
        after: 0,
      });
    }
  }

  const loadMoreCards = function () {
    let variables = { ...queryVariables };
    variables.after += cardCount;
    setQueryVariables(variables);
  };

  function tagClick(e) {
    scrollableContainerRef.current.elementClicked(e.target);
    setSelectedTagIndex(e.target.dataset.id);
    filterByTag(e.target.dataset.slug);
  }

  return (
    <>
      <div className='user-challenges'>
        {isMe ? <h3>Challenges</h3> : <h3>Winners</h3>}
        {tags.length > 1 && (
          <ScrollableContainer ref={scrollableContainerRef}>
            {tags.map((item) => (
              <li
                key={item.id}
                data-name={item.name}
                data-slug={item.slug}
                data-id={item.id}
                onClick={tagClick}
                tabIndex='0'
                className={item.id == selectedTagIndex ? 'active' : ''}
              >
                <span>{item.name}</span>
              </li>
            ))}
          </ScrollableContainer>
        )}

        <VisionBoard
          ref={visionBoardRef}
          query={queryGQL}
          object={queryObject}
          variables={queryVariables}
          loadMoreCards={loadMoreCards}
          className='show-background'
        />
      </div>
    </>
  );
};

export default UserChallenges;
