import React from 'react';
import { Spinner } from 'react-bootstrap';
import logo from '../assets/svg/lotb-logo.svg';

const PageLoader = (props) => {
  return (
    <div className={`page-loader ${props.className ? props.className : ''}`}>
      <Spinner animation='border' role='status'>
        <span className='sr-only'>Loading...</span>
      </Spinner>
      <img alt='logo' src={logo} />
    </div>
  );
};

export default PageLoader;
