import React, { useRef, useState, useEffect } from 'react';
import ScrollableContainer from './ScrollableContainer';
import ImageComponent from './Image';
import { useQuery } from '@apollo/client';
import { GET_AVATARS } from '../graphql/queries/profile.js';
import ComponentLoader from './ComponentLoader';
import ErrorComponent from './ErrorComponent';

const AvatarSelector = ({
  activeId = -1,
  avatarSelected,
  scrollerColor = 'red',
}) => {
  const scrollableContainerRef = useRef(null);
  const { loading, error } = useQuery(GET_AVATARS, {
    onCompleted: (data) => {
      setItems(data.getDoritosLotbAvatarListing.edges);
    },
  });

  const [initial, setInitial] = useState(true);
  const [items, setItems] = useState([]);

  const onClick = (e) => {
    avatarSelected(e.target.dataset.id);
    scrollableContainerRef.current.elementClicked(e.target);
  };

  useEffect(() => {
    if (!items || !items.length) return;
    if (initial && activeId > -1) {
      // reorder selected item to front
      const _items = items.filter((item) => item.node.id !== activeId);
      const item = items.find((item) => item.node.id === activeId);
      setItems([item, ..._items]);
      setInitial(false);
    }
  }, [items]);

  if (loading || !items) return <ComponentLoader />;
  if (error) return <ErrorComponent />;

  return (
    <ScrollableContainer
      scrollerColor={scrollerColor}
      ref={scrollableContainerRef}
    >
      {items.map((item, index) => (
        <li
          key={index}
          data-name={item.node.name ? item.node.name : item.node.alt}
          data-id={item.node.id}
          onClick={onClick}
          tabIndex='0'
          className={
            activeId !== -1 && item.node.id === activeId ? 'active' : ''
          }
        >
          <ImageComponent url={item.node.image.fullpath} alt={item.node.name} />
        </li>
      ))}
    </ScrollableContainer>
  );
};

export default AvatarSelector;
