import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import BackgroundImage from '../BackgroundImage';
import Button from 'react-bootstrap/Button';
import ItemsList from '../ItemsList';

import { useReactiveVar } from '@apollo/client';
import { userLogInData } from '../../graphql/reactivities/user';

import { useQuery, useMutation } from '@apollo/client';
import { GET_ALL_INTERESTS } from '../../graphql/queries/profile.js';
import { UPDATE_INTERESTS } from '../../graphql/mutations/user.js';
import InterestsList from '../InterestsList';
import ErrorComponent from '../ErrorComponent';

const InterestsScreen = ({ setup }) => {
  let history = useHistory();

  const userLogIn = useReactiveVar(userLogInData);
  const [selectedInterests, setSelectedInterests] = useState([]);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(
    'Sorry, there was an error saving the data.'
  );
  let numErrors = 0;

  const addRemoveInterest = (name) => {
    //const name = e.target.dataset.name;
    if (selectedInterests.findIndex((interest) => interest === name) >= 0) {
      setSelectedInterests((list) => list.filter((item) => item !== name));
    } else {
      setSelectedInterests((list) => [...list, name]);
    }
  };

  // UPDATE USER MUTATION
  const [updateUser] = useMutation(UPDATE_INTERESTS, {
    onCompleted: (data) => {
      if (data.updateDoritosLotbUser.success === true) {
        history.push('/setup/6');
      } else {
        numErrors++;
        if (numErrors > 1) {
          return <ErrorComponent />;
        } else {
          setHasError(true);
        }
      }
    },
    onError: (error) => {
      numErrors++;
      if (numErrors > 1) {
        return <ErrorComponent />;
      } else {
        setHasError(true);
      }
    },
  });

  const saveInterests = (e) => {
    e.preventDefault();
    updateUser({
      variables: {
        id: userLogIn.id,
        interests: selectedInterests,
      },
    });
  };

  return (
    <>
      <div className='background-image-container'>
        <BackgroundImage data={setup.image.background} />
      </div>
      <div className='content interests-screen'>
        <div className='content-holder'>
          <h2>{setup.metadata.header}</h2>

          {hasError && <p className='error'>{errorMessage}</p>}

          <InterestsList
            interestSelected={addRemoveInterest}
            selectedInterests={selectedInterests}
            allowSelect={true}
          />

          <Button tabIndex='0' variant='primary' onClick={saveInterests}>
            save &amp; continue
          </Button>

          <div className='next-prev-buttons'>
            {/* <span
              onClick={(e) => {
                e.preventDefault();
                history.goBack();
              }}
            >
              Back
            </span> */}
            <span
              onClick={(e) => {
                e.preventDefault();
                //resetSelectedInterests();
                history.push('/setup/6');
              }}
            >
              Skip
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default InterestsScreen;
