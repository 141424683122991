import React from 'react';
import { Helmet } from 'react-helmet';
import WinnersComponent from '../components/winners/Winners';

const Winners = ({ metadata }) => {
  return (
    <>
      <Helmet>
        <title> - Winners</title>
      </Helmet>
      <WinnersComponent metadata={metadata} />
    </>
  );
};

export default Winners;
