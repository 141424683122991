import React from 'react';
import ImageComponent from './Image';

const BackgroundImageSimple = (props) => {
  return (
    <>
      <ImageComponent
        className='background-image'
        alt='background image'
        url={props.url}
      />
    </>
  );
};

export default BackgroundImageSimple;
