import React, { useState } from 'react';
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import ImageComponent from '../Image';
import MediaCarousel from '../MediaCarousel';
import { ReactComponent as DeleteButton } from '../../assets/svg/close-button.svg';
import { ReactComponent as PlayButton } from '../../assets/svg/play-button.svg';
import { ReactComponent as FolderIcon } from '../../assets/svg/folder-icon.svg';

const ChallengeSubmissionAttachments = ({
  attachments,
  editing,
  removeAttachment,
  challengeId,
  submissionId,
  maxAttachments,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [modalStartingImage, setModalStartingImage] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [percentageLoaded, setPercentageLoaded] = useState(0);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(
    'There was an error uploading your attachment.'
  );

  const openAttachment = (index) => {
    setModalStartingImage(index);
    setShowModal(true);
  };

  const onFileChange = (e) => {
    // FILE IS READY TO GO..
    const file = e.target.files[0];
    setUploading(true);

    const url = process.env.REACT_APP_UPLOAD_API_URL;

    const formData = new FormData();
    formData.append('attachment', file);
    formData.append('challengeid', challengeId);
    formData.append('submissionid', submissionId);

    // ATTEMPT 3
    const axiosConfig = {
      credentials: 'include',
    };
    //axios.defaults.withCredentials = true;
    axios(url, {
      method: 'post',
      data: formData,
      withCredentials: true,
      onUploadProgress: (progressEvent) =>
        setPercentageLoaded((progressEvent.loaded / progressEvent.total) * 100),
    })
      .then((res) => {
        if (res.data.success) {
          const newObj = {
            id: res.data.data.objectid.toString(),
            attachmenttype: res.data.data.attachmenttype,
            attachment: {
              fullpath: res.data.data.attachment.path,
              type: res.data.data.attachmenttype,
            },
          };
          attachments.push(newObj);
        } else {
          setErrorMessage(res.data.msg);
          setHasError(true);
          setTimeout(() => {
            setHasError(false);
          }, 4000);
        }
        setUploading(false);
      })
      .catch((err) => {
        setUploading(false);
        setHasError(true);
        setTimeout(() => {
          setHasError(false);
        }, 4000);
      });
  };

  const handleClose = () => {
    setShowModal(false);
  };

  const handleRemove = (index) => {
    removeAttachment(attachments[index].id);
  };

  return (
    <>
      <div className='challenge-submission-attachments'>
        {hasError && <p className='error'>{errorMessage}</p>}
        {(!attachments || attachments.length === 0) && !editing && (
          <p>No Attachments</p>
        )}
        {((attachments && attachments.length > 0) || uploading) && (
          <ul className='attachments-list'>
            {attachments.map((attachment, index) => (
              <li key={index} className='attachment-holder'>
                {attachment.attachmenttype === 'image' && (
                  <>
                    <ImageComponent
                      url={attachment.attachment.fullpath}
                      alt={
                        attachment.alt ? attachment.alt : 'challenge attachment'
                      }
                      onClick={() => openAttachment(index)}
                    />
                  </>
                )}
                {attachment.attachmenttype === 'video' && (
                  <div
                    className='video-thumbnail'
                    onClick={() => openAttachment(index)}
                  >
                    <PlayButton />
                  </div>
                )}
                {attachment.attachmenttype === 'zip' && (
                  <div className='zip-thumbnail'>
                    <FolderIcon />
                    <p>Zip file</p>
                  </div>
                )}
                {editing && (
                  <div
                    className='delete-button'
                    onClick={() => handleRemove(index)}
                  >
                    <DeleteButton />
                  </div>
                )}
              </li>
            ))}
            {uploading && (
              <li className='attachment-holder uploading'>
                <div className='upload-progress-bar'>
                  <div
                    className='upload-progress-amount'
                    style={{ width: `${percentageLoaded}%` }}
                  ></div>
                </div>
              </li>
            )}
          </ul>
        )}

        {editing && !uploading && attachments.length < maxAttachments && (
          <div className='upload-button'>
            <label htmlFor={`fileupload-${submissionId}`}>Add a File</label>
            <input
              id={`fileupload-${submissionId}`}
              name='fileupload'
              type='file'
              onChange={onFileChange}
              style={{ display: 'none' }}
            />
          </div>
        )}
      </div>
      <Modal show={showModal} onHide={handleClose}>
        <Modal.Body>
          <MediaCarousel items={attachments} startIndex={modalStartingImage} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default ChallengeSubmissionAttachments;
