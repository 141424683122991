import React from 'react';
import ChallengesComponent from '../components/challenges/Challenges';
import Helmet from 'react-helmet';

const Challenges = ({ metadata }) => {
  return (
    <>
      <Helmet>
        <title> - Challenges</title>
      </Helmet>
      <ChallengesComponent metadata={metadata} />
    </>
  );
};

export default Challenges;
