import React from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

const NotificationModal = ({
  handleClose,
  title,
  message,
  buttonText,
  show,
}) => {
  return (
    <>
      <Modal className='notification-modal' show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <h2>{title}</h2>
          <p>{message}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='primary' onClick={handleClose}>
            {buttonText}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default NotificationModal;
