import React from 'react';
import { Helmet } from 'react-helmet';
import BackgroundImageSimple from '../BackgroundImageSimple';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import ChallengeInfo from './ChallengeInfo';
import ChallengeSubmissions from './ChallengeSubmissions';
import ImageComponent from '../Image';
import PageLoader from '../PageLoader';
import VisionBoardNoQuery from '../VisionBoardNoQuery';
import { getIdFromPath, challengeOpen } from '../../Helpers';
import { useReactiveVar } from '@apollo/client';
import { userLoggedIn, userLogInData } from '../../graphql/reactivities/user';
import { Redirect } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/client';
import { GET_CHALLENGE } from '../../graphql/queries/challenges.js';
import { METADATA_ADD } from '../../graphql/mutations/metadata.js';
import Button from 'react-bootstrap/Button';

import { ReactComponent as HollowChip } from '../../assets/svg/hollow-chip.svg';
import { ReactComponent as FacebookIcon } from '../../assets/svg/facebook-icon.svg';
import { ReactComponent as TwitterIcon } from '../../assets/svg/twitter-icon.svg';

import ErrorComponent from '../ErrorComponent';

const ChallengeComponent = () => {
  const challengeId = getIdFromPath();
  const userLogIn = useReactiveVar(userLogInData);
  const loggedIn = useReactiveVar(userLoggedIn);
  const userId = loggedIn ? userLogIn.id : 0;

  const { data, loading, error } = useQuery(GET_CHALLENGE, {
    variables: {
      id: challengeId,
      oid: challengeId,
      uid: userId,
      fetchPolicy: 'no-cache',
    },
  });

  const [saveInterest] = useMutation(METADATA_ADD, {
    onError: (error) => {
      return <ErrorComponent />;
    },
    update: (cache, data) => {
      const cached = cache.readQuery({
        query: GET_CHALLENGE,
        variables: {
          id: challengeId,
          oid: challengeId,
          uid: userId,
        },
      });
      let challengeUpdate = { ...cached.getDoritosLotbChallenge };
      challengeUpdate.interest = data.data.addMetaData.activity;
      cache.writeQuery({
        query: GET_CHALLENGE,
        data: { getDoritosLotbChallenge: challengeUpdate },
      });
    },
  });

  const setInterest = (activity) => {
    saveInterest({
      variables: { id: challengeId, uid: userId, activity: activity },
    });
  };

  if (loading || !data) return <PageLoader />;
  if (!data.getDoritosLotbChallenge) return <Redirect to='/404' />;
  if (error) return <ErrorComponent />;

  const challenge = data.getDoritosLotbChallenge;
  const interest =
    data.getInterest.interest || challenge.interest
      ? challenge.interest
        ? challenge.interest
        : data.getInterest.interest
      : '';
  const open = challengeOpen(challenge);

  let prizeTypes = [];
  if (challenge.cash && challenge.cash.length) prizeTypes.push('Cash');
  if (challenge.products && challenge.products.length)
    prizeTypes.push('Products');
  if (challenge.experiences && challenge.experiences.length)
    prizeTypes.push('Experiences');

  let winners = [];
  if (challenge.winners && challenge.winnersannounced) {
    challenge.winners.forEach((item) => {
      winners.push({
        node: {
          id: item.id,
          lookuptype: 'winner',
          title: item.title,
          lookupobject: item,
        },
      });
    });
  }

  function handleLogin() {
    const signIn = document.getElementsByClassName('login-link')[0];
    if (signIn) {
      signIn.click();
    }
  }

  return (
    <>
      <Helmet>
        <title> - Challenge - {challenge.title}</title>
      </Helmet>
      <section className='challenge-detail'>
        <div className='background-image-container'>
          {challenge.background != null && (
            <BackgroundImageSimple url={challenge.background.fullpath} />
          )}
        </div>
        <div className='graphics'>
          <HollowChip className='blue-hollow-chip' />
          <HollowChip className='red-hollow-chip' />
        </div>
        <div className='content'>
          <div className='content-left'>
            <div className='label-and-socials'>
              <span>Challenge</span>
              <div className='socials'>
                <FacebookShareButton url={window.location.href}>
                  <FacebookIcon />
                </FacebookShareButton>
                <TwitterShareButton url={window.location.href}>
                  <TwitterIcon />
                </TwitterShareButton>
              </div>
            </div>
            <div className='challenge-image-container'>
              <ImageComponent
                className='challenge-image'
                url={challenge.image.fullpath}
                alt={challenge.title}
              />
            </div>
          </div>
          <div className='content-right'>
            <ChallengeInfo
              data={challenge}
              open={open}
              loggedIn={loggedIn}
              interest={interest}
              setInterest={setInterest}
            />
            {open && !loggedIn && (
              <Button variant='primary' className={'sign-in-btn'} onClick={handleLogin}>
                Sign In To Submit
              </Button>
            )}
            {loggedIn && (
              <ChallengeSubmissions
                open={open}
                challengeId={challengeId}
                userId={userId}
                maxAttachments={challenge.maxattachments}
                maxEntries={challenge.maxentries}
                prizeTypes={prizeTypes}
              />
            )}
          </div>
        </div>
        {winners.length > 0 && (
          <section className='winners-section'>
            <h2>Winners</h2>
            <VisionBoardNoQuery cards={winners} className='show-background' />
          </section>
        )}
      </section>
    </>
  );
};

export default ChallengeComponent;
