import React, { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';

const ImageComponent = ({
  url,
  alt,
  className,
  onClick,
  srcSet,
  sizes,
  isBackground,
}) => {
  const [imageLoaded, setImageLoaded] = useState(false);

  // setup image attributes
  let imageProps = {
    src: url,
    alt: alt,
  };
  if (onClick) imageProps.onClick = onClick;
  if (className) imageProps.className = className;
  if (srcSet) imageProps.srcSet = srcSet;
  if (sizes) imageProps.sizes = sizes;

  const imageHasLoaded = () => {
    setImageLoaded(true);
  };

  useEffect(() => {
    const img = new Image();
    img.addEventListener('load', imageHasLoaded);
    img.src = url;
    return () => {
      // cleanup
      img && img.removeEventListener('load', imageHasLoaded);
    };
  }, [url]);

  return (
    <>
      {imageLoaded ? (
        <img alt='' {...imageProps} />
      ) : (
        <div className={`image-loader${isBackground ? ' is-background' : ''}`}>
          <Spinner animation='border' role='status'>
            <span className='sr-only'>Loading...</span>
          </Spinner>
        </div>
      )}
    </>
  );
};

export default ImageComponent;
