import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import FontFaceObserver from 'fontfaceobserver';
import PageLoader from './PageLoader';
import { useReactiveVar } from '@apollo/client';
import { siteLoading } from '../graphql/reactivities/site';
import { useHistory } from 'react-router';

const Layout = ({ metadata, children }) => {
  const [fontsLoading, setFontsLoading] = useState(true);
  const siteIsLoading = useReactiveVar(siteLoading);
  const history = useHistory();

  useEffect(() => {
    history.listen(() => {
      if (metadata.googleAnalytics) {
        setTimeout(() => {
          window.dataLayer.push({
            event: 'pageview',
            page: {
              url: window.location.href,
              title: document.getElementsByTagName('title')[0].innerHTML
            }
          });
        }, 250);
      }
    });
  }, [history]);

  useEffect(() => {
    var fontAnton = new FontFaceObserver('Anton', { weight: 400 });
    var fontOpenSans = new FontFaceObserver('Open Sans', { weight: 600 });
    var fontOpenSansCondensed = new FontFaceObserver('Open Sans Condensed', {
      weight: 300,
    });
    var fontOpenSansCondensedBold = new FontFaceObserver(
      'Open Sans Condensed',
      { weight: 700 }
    );

    Promise.all([
      fontAnton.load(null, 10000),
      fontOpenSans.load(null, 10000),
      fontOpenSansCondensed.load(null, 10000),
      fontOpenSansCondensedBold.load(null, 10000),
    ]).then(function () {
      setFontsLoading(false);
    });
  }, []);

  return (
    <>
      <Helmet
        defaultTitle={metadata.title}
        titleTemplate={`${metadata.title}%s`}
      >
        <meta name='description' content={metadata.description} />
      </Helmet>
      {(fontsLoading || siteIsLoading) && <PageLoader />}
      <main>{children}</main>
    </>
  );
};

export default Layout;
