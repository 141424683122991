import React from 'react';
import { useRef } from 'react';
import { Button } from 'react-bootstrap';
import BackgroundImage from '../BackgroundImage';
import { useHistory } from 'react-router-dom';

const FeaturedChallenge = (props) => {
  const history = useHistory();
  const featuredChallengeRef = useRef(null);

  const buttonClick = () => {
    if (props.metadata.challenge) {
      history.push(`/challenges/${props.metadata.challenge}`);
    } else if (props.metadata.winner) {
      history.push(`/winners/${props.metadata.winner}`);
    } else if (props.metadata.username) {
      history.push(`/users/${props.metadata.username}`);
    } else if (props.metadata.url) {
      if (props.metadata.newWindow) {
        window.open(props.metadata.url);
      } else {
        window.location.href = props.metadata.url;
      }
    }
  };

  return (
    <>
      <section className='featured-challenge' ref={featuredChallengeRef}>
        <BackgroundImage data={props.backgroundImage} />
        <div className='details'>
          <h2>{props.title}</h2>
          <div
            className='description'
            dangerouslySetInnerHTML={{ __html: props.description }}
          ></div>
          <div
            className='border left'
            style={{ backgroundColor: props.borderColor }}
          ></div>
          <div
            className='border right'
            style={{ backgroundColor: props.borderColor }}
          ></div>
          {props.buttonText && (
            <Button
              style={{
                color: props.metadata.buttonTextColor,
                backgroundColor: props.metadata.buttonColor,
                borderColor: props.metadata.buttonColor,
              }}
              variant='secondary'
              onClick={buttonClick}
            >
              {props.buttonText}
            </Button>
          )}
        </div>
      </section>
    </>
  );
};

export default FeaturedChallenge;
