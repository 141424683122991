import React, { useRef } from 'react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Button from 'react-bootstrap/Button';
import SearchInput from '../SearchInput';
import BackgroundImage from '../BackgroundImage';
import BackgroundVideo from '../BackgroundVideo';
import { buildSignInURL } from '../../Helpers';
import { isMobile } from 'react-device-detect';
import { useReactiveVar } from '@apollo/client';
import { userLoggedIn } from '../../graphql/reactivities/user';

import { ReactComponent as BigSplash } from '../../assets/svg/splash-1.svg';
import { ReactComponent as HollowChip } from '../../assets/svg/hollow-chip.svg';
import { ReactComponent as FullChip } from '../../assets/svg/full-chip.svg';
import { ReactComponent as DownArrows } from '../../assets/svg/down-arrows.svg';
import { ReactComponent as Mountains } from '../../assets/svg/mountains.svg';

const Hero = (props) => {
  const heroRef = useRef(null);
  const bigSplashRef = useRef(null);
  const blueHollowChipRef = useRef(null);
  const fullChipOneRef = useRef(null);
  const fullChipTwoRef = useRef(null);

  const loggedIn = useReactiveVar(userLoggedIn);
  gsap.registerPlugin(ScrollTrigger);

  // Scroll animations? turned off for now
  // useEffect(() => {
  //   const bigSplash = bigSplashRef.current;
  //   const hero = heroRef.current;
  //   gsap.to('.graphics svg', {
  //     y: 25,
  //     stagger: 0.2,
  //     scrollTrigger: {
  //       trigger: hero,
  //       start: 'top top',
  //       end: 'bottom center',
  //       scrub: true,
  //     },
  //   });
  // }, []);

  const signUp = () => {
    window.location.href = buildSignInURL(props.buttonURL);
  };

  const doSearch = (searchText) => {
    //console.log('search', searchText);
  };

  return (
    <>
      <section
        className={`hero${
          props.backgroundVideo && !isMobile ? ' has-video' : ''
        }`}
        ref={heroRef}
      >
        {props.backgroundVideo && !isMobile ? (
          <BackgroundVideo url={props.backgroundVideo} />
        ) : props.backgroundImage ? (
          <BackgroundImage data={props.backgroundImage} />
        ) : null}
        <div className='bottom-gradient'></div>
        <div className='graphics'>
          <BigSplash className='big-splash' ref={bigSplashRef} />
          <HollowChip className='blue-hollow-chip' ref={blueHollowChipRef} />
          <HollowChip className='green-hollow-chip' />
          <HollowChip className='yellow-hollow-chip' />
          <Mountains className='mountains' />
          <FullChip className='full-chip-one' ref={fullChipOneRef} />
          <FullChip className='full-chip-two' ref={fullChipTwoRef} />
        </div>
        <h1>{props.heading}</h1>
        <div
          className='intro'
          dangerouslySetInnerHTML={{ __html: props.intro }}
        ></div>
        {!loggedIn && (
          <Button
            style={{
              color: props.metadata.buttonTextColor,
              backgroundColor: props.metadata.buttonColor,
              borderColor: props.metadata.buttonColor,
            }}
            tabIndex='0'
            onClick={signUp}
          >
            {props.buttonText}
          </Button>
        )}
        {props.searchDisplay && (
          <SearchInput placeholder={props.searchText} onSubmit={doSearch} />
        )}
        <DownArrows
          tabIndex='0'
          className='scroll-button'
          onClick={props.triggerScrollDown}
        />
      </section>
    </>
  );
};

export default Hero;
