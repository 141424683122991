import React, {
  forwardRef,
  useRef,
  useState,
  useEffect,
  useImperativeHandle,
} from 'react';
import { Form, Col } from 'react-bootstrap';

const EditUserSocials = forwardRef(
  ({ userSocials, updateUserSocials, setSocialsFormValid }, ref) => {
    const formRef = useRef(null);

    const [validated, setValidated] = useState(false);
    const [hasError, setHasError] = useState(false);

    Object.keys(userSocials).forEach((social) => {
      if (!userSocials[social]) {
        userSocials[social] = '';
      }
    });

    useImperativeHandle(ref, () => ({
      triggerFormSubmit() {
        submitForm();
      },
    }));

    useEffect(() => {
      formRef.current.addEventListener('click', () => {
        setHasError(false);
      });
    }, []);

    const submitForm = () => {
      const form = formRef.current;
      if (form.checkValidity() === false) {
        // not valid
        setHasError(true);
      } else {
        // valid
        setHasError(false);
        setSocialsFormValid(true);
      }
      setValidated(true);
    };

    const onURLChange = (e) => {
      const platform = e.target.dataset.platform;
      updateUserSocials({ [platform]: e.target.value });
    };

    return (
      <div className='edit-user-socials'>
        <h2>Socials</h2>
        <Form
          noValidate
          validated={validated}
          onSubmit={submitForm}
          ref={formRef}
        >
          <Form.Row>
            <Form.Group as={Col} controlId='twitter'>
              <Form.Label>twitter profile URL</Form.Label>
              <Form.Control
                name='twitter'
                type='text'
                data-platform='twitter'
                pattern='^https:\/\/(.*)'
                value={userSocials.twitter || ''}
                onChange={onURLChange}
              />
              <p className='invalid-feedback'>
                Profile URL must start with 'https://'
              </p>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId='youtube'>
              <Form.Label>youtube channel URL</Form.Label>
              <Form.Control
                name='youtube'
                type='text'
                data-platform='youtube'
                pattern='^https:\/\/(.*)'
                value={userSocials.youtube || ''}
                onChange={onURLChange}
              />
              <p className='invalid-feedback'>
                Profile URL must start with 'https://'
              </p>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId='instagram'>
              <Form.Label>instagram Profile URL</Form.Label>
              <Form.Control
                name='instagram'
                type='text'
                data-platform='instagram'
                pattern='^https:\/\/(.*)'
                value={userSocials.instagram || ''}
                onChange={onURLChange}
              />
              <p className='invalid-feedback'>
                Profile URL must start with 'https://'
              </p>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId='linkedin'>
              <Form.Label>linkedin profile URL</Form.Label>
              <Form.Control
                name='linkedin'
                type='text'
                data-platform='linkedin'
                pattern='^https:\/\/(.*)'
                value={userSocials.linkedin || ''}
                onChange={onURLChange}
              />
              <p className='invalid-feedback'>
                Profile URL must start with 'https://'
              </p>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId='tiktok'>
              <Form.Label>tiktok profile URL</Form.Label>
              <Form.Control
                name='tiktok'
                type='text'
                data-platform='tiktok'
                pattern='^https:\/\/(.*)'
                value={userSocials.tiktok || ''}
                onChange={onURLChange}
              />
              <p className='invalid-feedback'>
                Profile URL must start with 'https://'
              </p>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId='behance'>
              <Form.Label>behance profile URL</Form.Label>
              <Form.Control
                name='behance'
                type='text'
                data-platform='behance'
                pattern='^https:\/\/(.*)'
                value={userSocials.behance || ''}
                onChange={onURLChange}
              />
              <p className='invalid-feedback'>
                Profile URL must start with 'https://'
              </p>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId='facebook'>
              <Form.Label>facebook profile URL</Form.Label>
              <Form.Control
                name='facebook'
                type='text'
                data-platform='facebook'
                pattern='^https:\/\/(.*)'
                value={userSocials.facebook || ''}
                onChange={onURLChange}
              />
              <p className='invalid-feedback'>
                Profile URL must start with 'https://'
              </p>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId='twitch'>
              <Form.Label>twitch profile URL</Form.Label>
              <Form.Control
                name='twitch'
                type='text'
                data-platform='twitch'
                pattern='^https:\/\/(.*)'
                value={userSocials.twitch || ''}
                onChange={onURLChange}
              />
              <p className='invalid-feedback'>
                Profile URL must start with 'https://'
              </p>
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Form.Group as={Col} controlId='snapchat'>
              <Form.Label>snapchat profile URL</Form.Label>
              <Form.Control
                name='snapchat'
                type='text'
                data-platform='snapchat'
                pattern='^https:\/\/(.*)'
                value={userSocials.snapchat || ''}
                onChange={onURLChange}
              />
              <p className='invalid-feedback'>
                Profile URL must start with 'https://'
              </p>
            </Form.Group>
          </Form.Row>
        </Form>
      </div>
    );
  }
);

export default EditUserSocials;
