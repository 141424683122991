import React from 'react';
import ReactPlayer from 'react-player';
import ImageComponent from './Image';
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';

import leftButton from '../assets/svg/left-arrow.svg';
import rightButton from '../assets/svg/right-arrow.svg';

const MediaCarousel = ({ items, width = 1920, height = 1080, startIndex }) => {
  items = items.filter(
    (item) => item.attachmenttype === 'image' || item.attachmenttype === 'video'
  );
  return (
    <div className='media-carousel'>
      <div
        className={`carousel-container${
          items.length === 1 ? ' one-slide' : ''
        }`}
      >
        <CarouselProvider
          naturalSlideWidth={width}
          naturalSlideHeight={height}
          totalSlides={items.length}
          currentSlide={startIndex ? startIndex : 0}
        >
          <ButtonBack>
            <img src={leftButton} alt='carousel left' />
          </ButtonBack>
          <Slider aria-label="Media Carousel">
            {items.map((item, index) => (
              <Slide index={index} key={index}>
                {item.attachmenttype === 'video' && (
                  <div className='player-wrapper'>
                    <ReactPlayer
                      className='react-player'
                      controls
                      url={item.attachment.fullpath}
                      width='100%'
                      height='100%'
                    />
                  </div>
                )}
                {item.attachmenttype === 'image' && (
                  <ImageComponent
                    url={item.attachment.fullpath}
                    alt={item.alt ? item.alt : 'Challenge Image'}
                  />
                )}
              </Slide>
            ))}
          </Slider>

          <ButtonNext>
            <img src={rightButton} alt='carousel right' />
          </ButtonNext>
        </CarouselProvider>
      </div>
    </div>
  );
};

export default MediaCarousel;
