import React, {
  useEffect,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

import { ReactComponent as LeftArrow } from '../assets/svg/left-arrow.svg';
import { ReactComponent as RightArrow } from '../assets/svg/right-arrow.svg';

const ScrollableContainer = forwardRef(
  ({ onePanel = false, children, scrollerColor = 'red' }, ref) => {
    // REFs
    const containerRef = useRef(null);
    const itemListRef = useRef(null);

    gsap.registerPlugin(ScrollToPlugin);

    const checkOverflow = () => {
      if (!itemListRef || !itemListRef.current) return;
      if (itemListRef.current.scrollWidth > itemListRef.current.offsetWidth) {
        containerRef.current.classList.add('has-overflow');
      } else {
        containerRef.current.classList.remove('has-overflow');
      }
    };

    const checkContainerScroll = () => {
      if (!containerRef.current) return;

      // hide buttons by default
      containerRef.current
        .getElementsByClassName('scroller-button')[0]
        .classList.add('disabled');
      containerRef.current
        .getElementsByClassName('scroller-button')[1]
        .classList.add('disabled');

      const scrollPos =
        itemListRef.current.scrollWidth - itemListRef.current.scrollLeft;
      const containerWidth = itemListRef.current.offsetWidth;

      if (itemListRef.current.scrollWidth > itemListRef.current.offsetWidth) {
        // need to scroll.. show/hide the scroll buttons depending on scroll position
        // if this is the first time (or after resize) we set the "has-overflow" class as well

        if (itemListRef.current.scrollLeft === 0) {
          containerRef.current
            .getElementsByClassName('scroller-button')[1]
            .classList.remove('disabled');
        } else if (scrollPos === containerWidth) {
          containerRef.current
            .getElementsByClassName('scroller-button')[0]
            .classList.remove('disabled');
        } else {
          containerRef.current
            .getElementsByClassName('scroller-button')[0]
            .classList.remove('disabled');
          containerRef.current
            .getElementsByClassName('scroller-button')[1]
            .classList.remove('disabled');
        }
      }
    };

    const scrollIt = (dir) => {
      const newPos = onePanel
        ? itemListRef.current.scrollLeft + dir * itemListRef.current.offsetWidth
        : itemListRef.current.scrollLeft + dir * 200;
      scrollContainerTo(newPos);
    };

    const scrollContainerTo = (position) => {
      gsap.to(itemListRef.current, {
        duration: 1,
        ease: 'power2',
        scrollTo: { y: 0, x: position },
      });
    };

    useImperativeHandle(ref, () => ({
      elementClicked(elem) {
        if (
          elem.getBoundingClientRect().left >
            itemListRef.current.getBoundingClientRect().left &&
          elem.getBoundingClientRect().left + elem.offsetWidth <
            itemListRef.current.getBoundingClientRect().left +
              itemListRef.current.offsetWidth
        ) {
          // element is in view
          return;
        }

        if (
          elem.getBoundingClientRect().left <
          itemListRef.current.getBoundingClientRect().left
        ) {
          // out of view to the left -- scrolling right
          scrollContainerTo(
            itemListRef.current.scrollLeft -
              (itemListRef.current.getBoundingClientRect().left -
                elem.getBoundingClientRect().left) -
              10
          );
        } else if (
          elem.offsetLeft + elem.offsetWidth >
          itemListRef.current.offsetWidth
        ) {
          // out of view to the right -- scrolling left
          scrollContainerTo(
            elem.offsetLeft + elem.offsetWidth - itemListRef.current.offsetWidth
          );
        }
      },
    }));

    useEffect(() => {
      checkContainerScroll();
      checkOverflow();
      itemListRef.current.addEventListener('scroll', checkContainerScroll);
      window.addEventListener('resize', () => {
        checkOverflow();
        checkContainerScroll();
      });
      return () => {
        // cleanup
        window.removeEventListener('resize', checkContainerScroll);
      };
    }, [children]);

    return (
      <div
        className={`scrollable-container scroller-${scrollerColor}`}
        ref={containerRef}
      >
        <LeftArrow
          className='scroller-button left'
          tabIndex='0'
          onClick={() => scrollIt(-1)}
        />
        <ul ref={itemListRef}>{children}</ul>
        <RightArrow
          className='scroller-button right'
          tabIndex='0'
          onClick={() => scrollIt(1)}
        />
      </div>
    );
  }
);

export default ScrollableContainer;
