import React from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import ImageComponent from './Image';
import { challengeOpen } from '../Helpers';
import prizeIcon from '../assets/svg/prize-ribbon-icon.svg';
import thumbIcon from '../assets/svg/thumb-icon.svg';

const VisionBoardCard = (props) => {
  let item = {};
  if (
    props.data.__typename == 'object_DoritosLotbWinner' ||
    props.data.__typename == 'object_DoritosLotbChallenge'
  ) {
    item.id = props.data.id;
    item.title = props.data.title;
    item.lookupobject = props.data;
    item.lookuptype =
      props.data.__typename == 'object_DoritosLotbWinner'
        ? 'winner'
        : 'challenge';
  } else {
    item = { ...props.data };
  }

  const history = useHistory();

  const type = item.lookuptype;
  const layout = item.lookupobject.card ? item.lookupobject.card : 'partial';

  const image =
    type == 'challenge'
      ? (item.lookupobject.image) ? item.lookupobject.image.fullpath : ''
      : (item.lookupobject.thumbnail) ? item.lookupobject.thumbnail.fullpath : '';

  const titleIcon =
    type == 'challenge'
      ? (item.lookupobject.category) ? item.lookupobject.category.icon.fullpath : ''
      : (item.lookupobject.challenge && item.lookupobject.challenge.category) ? item.lookupobject.challenge.category.icon.fullpath : '';

  const category =
    type == 'challenge'
      ? (item.lookupobject.category) ? item.lookupobject.category.name : ''
      : (item.lookupobject.challenge && item.lookupobject.challenge.category) ? item.lookupobject.challenge.category.name : '';

  const renderButton = (item) => {
    let buttonTitle = 'view it';
    if (challengeOpen(item.lookupobject)) {
      buttonTitle = 'enter it';
    }
    return (
      <Button
        tabIndex='0'
        variant='secondary'
        onClick={() => viewItem(item.lookuptype, item.lookupobject.slug)}
      >
        {buttonTitle}
      </Button>
    );
  };

  const renderUser = (item) => {
    if (item.lookuptype == 'winner' && item.lookupobject.user) {
      return (
        <div className='user-info'>
          <ImageComponent
            alt={item.lookupobject.user.username}
            url={item.lookupobject.user.avatar.image.fullpath}
          />
          <p role='heading' aria-level='3' className='info-label'>
            {item.lookupobject.user.username}
          </p>
        </div>
      );
    }
  };

  const renderInfo = (item) => {
    let items = [];

    if (item.lookuptype == 'winner') {
      if (item.lookupobject.likes != null) {
        items.push({
          label: `${item.lookupobject.likes} ${
            item.lookupobject.likes == 1 ? ' Like' : ' Likes'
          }`,
          icon: thumbIcon,
        });
      }
      if (item.lookupobject.place) {
        items.push({
          label: `${item.lookupobject.place} Place`,
          icon: prizeIcon,
        });
      }
    }
    if (items.length > 0) {
      return (
        <div className='item-info'>
          {items.map((item, index) => (
            <div key={index}>
              <img alt={item.label} src={item.icon} />
              <p role='heading' aria-level='3' className='info-label'>
                {item.label}
              </p>
            </div>
          ))}
        </div>
      );
    }
  };

  const renderDescription = (item) => {
    if (item.lookupobject.blurb && type === 'challenge') {
      return <p className='description'>{item.lookupobject.blurb}</p>;
    }
  };

  const renderPrizeInfo = (item) => {
    if (type === 'challenge') {
      return (
        <div className='prize-info'>
          {item.lookupobject.topprize && (
            <p className='info-label'>
              Top Prize: {item.lookupobject.topprize}
            </p>
          )}
          {/* <p className='info-label'>Status - {item.lookupobject.status}</p> */}
        </div>
      );
    }
  };

  const renderChallengeStatus = (item) => {
    const status = item.lookupobject.status;
    let text = '';

    if (status.indexOf('Announced') > -1) {
      text = 'Announced';
    } else if (status.indexOf('Closed') > -1) {
      text = 'Closed';
    } else if (status.indexOf('Open') > -1) {
      text = status.replace('Open, ', '');
    }
    return <span>{text}</span>;
  };

  const viewItem = (type, slug) => {
    history.push(`/${type}s/${slug}`);
  };

  return (
    <>
      <div className={`${type} vision-board-card ${layout ? layout : ''}`}>
        <div className='card-header'>
          <span>{`challenge ${type === 'winner' ? 'winner' : ''}`}</span>
          {type === 'challenge' && <span>{renderChallengeStatus(item)}</span>}
        </div>
        <div className='bottom-border'></div>
        <ImageComponent className='card-image' alt='' url={image} />

        <div className='details'>
          {layout === 'full' ? (
            <>
              {/* Full BG IMAGE */}
              <span
                className='card-title'
                role='heading'
                aria-level='2'
                dangerouslySetInnerHTML={{
                  __html: item.title
                    ? item.title.replace(/[®]/g, '<sup>&reg;</sup>')
                    : '',
                }}
              ></span>
              {renderUser(item)}
              {renderInfo(item)}
              {renderButton(item)}
            </>
          ) : (
            <>
              {/* PARTIAL BG IMAGE */}
              <div className='title-bar'>
                <span
                  className='card-title'
                  role='heading'
                  aria-level='2'
                  dangerouslySetInnerHTML={{
                    __html: item.title
                      ? item.title.replace(/[®]/g, '<sup>&reg;</sup>')
                      : '',
                  }}
                ></span>
                {titleIcon && <ImageComponent alt={category} url={titleIcon} />}
              </div>
              {renderUser(item)}
              {renderInfo(item)}
              {renderDescription(item)}
              {renderPrizeInfo(item)}
              {renderButton(item)}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default VisionBoardCard;
