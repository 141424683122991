import React, { useRef } from 'react';
import { Helmet } from 'react-helmet';
import { Link, Redirect } from 'react-router-dom';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
import MediaCarousel from '../MediaCarousel';
import ImageComponent from '../Image';
import PageLoader from '../PageLoader';

import { getIdFromPath } from '../../Helpers';

import { useReactiveVar } from '@apollo/client';
import { userLoggedIn, userLogInData } from '../../graphql/reactivities/user';

import { useQuery, useMutation } from '@apollo/client';
import { GET_WINNER } from '../../graphql/queries/winners.js';
import { METADATA_ADD } from '../../graphql/mutations/metadata.js';

import gsap from 'gsap';

import { ReactComponent as HollowChip } from '../../assets/svg/hollow-chip.svg';
import { ReactComponent as LikesIcon } from '../../assets/svg/thumb-icon.svg';
import { ReactComponent as FacebookIcon } from '../../assets/svg/facebook-icon.svg';
import { ReactComponent as TwitterIcon } from '../../assets/svg/twitter-icon.svg';
import ErrorComponent from '../ErrorComponent';

const WinnerComponent = ({ id }) => {
  const winnerId = getIdFromPath();
  const userLogIn = useReactiveVar(userLogInData);
  const loggedIn = useReactiveVar(userLoggedIn);
  const userId = loggedIn ? userLogIn.id : 0;

  const likesRef = useRef(null);

  const t1 = gsap.timeline();
  const likeIt = (e) => {
    if (!loggedIn || liked) {
      return;
    }
    likeWinner({
      variables: { id: winner.id, uid: userLogIn.id, activity: 'like' },
    });
    e.currentTarget.classList.add('liked');
    t1.to(e.currentTarget, {
      scale: 1.2,
      duration: 0.1,
      ease: 'easeIn',
    }).to(e.currentTarget, {
      scale: 1,
      duration: 0.1,
      ease: 'easeIn',
    });
  };

  const [likeWinner] = useMutation(METADATA_ADD, {
    onCompleted: (data) => {
      likesRef.current.textContent = winner.likes.toLocaleString();
    },
    onError: (error) => {
      // console.log('ERROR || ALREADY LIKED || MUTATION COMPLETE' );
    },
    update: (cache, data) => {
      const cached = cache.readQuery({
        query: GET_WINNER,
        variables: {
          id: winnerId,
          oid: winnerId,
          uid: userId,
        },
      });
      let winnerUpdate = { ...cached.getDoritosLotbWinner };
      winnerUpdate.likes = winnerUpdate.likes + 1;
      winnerUpdate.liked = true;
      cache.writeQuery({
        query: GET_WINNER,
        data: { getDoritosLotbWinner: winnerUpdate },
      });
    },
  });

  const { data, loading, error } = useQuery(GET_WINNER, {
    variables: {
      id: winnerId,
      oid: winnerId,
      uid: userId,
      fetchPolicy: 'no-cache',
    },
  });

  if (loading || !data) return <PageLoader />;
  if (error) return <ErrorComponent />;
  if (!data.getDoritosLotbWinner) return <Redirect to='/404' />;

  const winner = data.getDoritosLotbWinner;
  const liked = data.getLike.liked || winner.liked ? true : false;

  // TMP media data formatting
  const media = [];
  if (!winner.video && winner.image) {
    media.push({
      attachment: {
        fullpath: winner.image.fullpath,
      },
      alt: winner.title,
      id: 1,
      attachmenttype: 'image',
    });
  } else if (winner.video) {
    media.push({
      attachment: {
        fullpath: winner.video.fullpath,
      },
      alt: winner.title,
      id: 1,
      attachmenttype: 'video',
    });
  }

  return (
    <>
      <Helmet>
        <title> - Winner - {winner.title}</title>
      </Helmet>
      <div className='winner-detail container'>
        <div className='graphics'>
          <HollowChip className='blue-hollow-chip' />
          <HollowChip className='red-hollow-chip' />
        </div>
        {media.length > 0 && <MediaCarousel items={media} />}
        <div className='content'>
          <h1>{winner.title}</h1>

          <p className='challenge-info'>
            <span>
              {winner.place} Place: <em>{winner.prize}</em>
            </span>
            <span>
              Challenge:{' '}
              <Link to={`/challenges/${winner.challenge.slug}`}>
                <em>{winner.challenge.title}</em>
              </Link>
            </span>
          </p>

          <div dangerouslySetInnerHTML={{ __html: winner.description }}></div>

          {winner.tags != null && (
            <div className='tags'>
              <p>Tags</p>
              <ul className='items-list'>
                {winner.tags.map((tag, index) => (
                  <li key={index}>{`// ${tag}`}</li>
                ))}
              </ul>
            </div>
          )}
          <div className='user-info'>
            {winner.user != null && (
              <div className='avatar'>
                <div className='avatar-container'>
                  <ImageComponent
                    className='avatar'
                    url={winner.user.avatar.image.fullpath}
                    alt={`${winner.user.username}`}
                  />
                </div>
                <Link to={`/users/${winner.user.username}`}>
                  <span>{winner.user.username}</span>
                </Link>
              </div>
            )}
            <div className='social'>
              <div
                className={
                  'likes' +
                  (liked ? ' liked' : '') +
                  (!loggedIn ? ' not-logged-in' : '')
                }
                onClick={likeIt}
              >
                <span ref={likesRef}>{winner.likes.toLocaleString()}</span>
                <LikesIcon />
              </div>
              <FacebookShareButton url={window.location.href}>
                <FacebookIcon />
              </FacebookShareButton>
              <TwitterShareButton url={window.location.href}>
                <TwitterIcon />
              </TwitterShareButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WinnerComponent;
