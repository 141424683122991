import { gql } from '@apollo/client';

export const UPDATE_USER = gql`
  mutation updateDoritosLotbUser(
    $id: Int!
    $firstname: String!
    $lastname: String!
    $address: String!
    $city: String!
    $state: String!
    $zip: String!
    $optin: Boolean!
    $avatarid: Int!
    $headerid: Int!
    $interests: [String]!
    $twitter: String!
    $youtube: String!
    $instagram: String!
    $linkedin: String!
    $tiktok: String!
    $behance: String!
    $facebook: String!
    $snapchat: String!
    $twitch: String!
  ) {
    updateDoritosLotbUser(
      id: $id
      input: {
        avatar: { id: $avatarid, type: "object" }
        profileheader: { id: $headerid, type: "object" }
        firstname: $firstname
        lastname: $lastname
        address: $address
        city: $city
        state: $state
        zip: $zip
        interests: $interests
        twitter: $twitter
        instagram: $instagram
        optin: $optin
        youtube: $youtube
        behance: $behance
        tiktok: $tiktok
        linkedin: $linkedin
        facebook: $facebook
        snapchat: $snapchat
        twitch: $twitch
      }
    ) {
      success
    }
  }
`;

export const UPDATE_USERNAME = gql`
  mutation updateDoritosLotbUser(
    $id: Int!
    $username: String!
    $optin: Boolean!
    $setup: Boolean!
  ) {
    updateDoritosLotbUser(
      id: $id
      input: { username: $username, optin: $optin, setup: $setup }
    ) {
      success
    }
  }
`;

export const UPDATE_AVATAR = gql`
  mutation updateDoritosLotbUser($id: Int!, $oid: Int!) {
    updateDoritosLotbUser(
      id: $id
      input: { avatar: { id: $oid, type: "object" } }
    ) {
      success
    }
  }
`;

export const UPDATE_HEADER = gql`
  mutation updateDoritosLotbUser($id: Int!, $oid: Int!) {
    updateDoritosLotbUser(
      id: $id
      input: { profileheader: { id: $oid, type: "object" } }
    ) {
      success
    }
  }
`;

export const UPDATE_FAVORITES = gql`
  mutation updateDoritosLotbUser(
    $id: Int!
    $tshirtsize: String!
    $flavor: String!
    $platform: String!
  ) {
    updateDoritosLotbUser(
      id: $id
      input: {
        tshirtsize: $tshirtsize
        favoriteflavor: $flavor
        favoriteplatform: $platform
      }
    ) {
      success
    }
  }
`;

export const UPDATE_INTERESTS = gql`
  mutation updateDoritosLotbUser($id: Int!, $interests: [String]!) {
    updateDoritosLotbUser(id: $id, input: { interests: $interests }) {
      success
    }
  }
`;

export const UPDATE_SOCIALS = gql`
  mutation updateDoritosLotbUser(
    $id: Int!
    $twitter: String!
    $youtube: String!
    $instagram: String!
    $linkedin: String!
    $tiktok: String!
    $behance: String!
    $facebook: String!
    $twitch: String!
    $snapchat: String!
  ) {
    updateDoritosLotbUser(
      id: $id
      input: {
        twitter: $twitter
        youtube: $youtube
        instagram: $instagram
        linkedin: $linkedin
        tiktok: $tiktok
        behance: $behance
        facebook: $facebook
        twitch: $twitch
        snapchat: $snapchat
      }
    ) {
      success
    }
  }
`;

export const UPDATE_ADDRESS = gql`
  mutation updateDoritosLotbUser(
    $id: Int!
    $firstname: String!
    $lastname: String!
    $address: String!
    $city: String!
    $state: String!
    $zip: String!
  ) {
    updateDoritosLotbUser(
      id: $id
      input: {
        firstname: $firstname
        lastname: $lastname
        address: $address
        city: $city
        state: $state
        zip: $zip
      }
    ) {
      success
    }
  }
`;

export const CLEAR_NOTIFICATION = gql`
  mutation clearNotification($uid: Int!, $nid: String!) {
    clearNotification(input: { user_id: $uid, nid: $nid })
  }
`;
