import React, { useState } from 'react';
import InterestsList from '../InterestsList';

const EditUserInterests = ({ selectedInterests, addRemoveInterest }) => {
  const [showAll, setShowAll] = useState(false);

  return (
    <>
      <InterestsList
        showAll={showAll}
        allowSelect={showAll}
        selectedInterests={selectedInterests}
        interestSelected={addRemoveInterest}
      />
      <span
        onClick={(e) => {
          e.preventDefault();
          setShowAll(!showAll);
        }}
      >
        {showAll ? `Show Less` : `Show More`}
      </span>
    </>
  );
};

export default EditUserInterests;
