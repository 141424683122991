import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_USER_LEVELS } from '../../graphql/queries/profile.js';
import ComponentLoader from '../ComponentLoader';
import ErrorComponent from '../ErrorComponent';

import { ReactComponent as ProgressIndicator } from '../../assets/svg/progress-indicator.svg';

const UserProgress = ({ currentPoints, maximumLevelMessage }) => {
  const { loading, error, data } = useQuery(GET_USER_LEVELS);

  if (loading || !data) return <ComponentLoader />;
  if (error || !data.getDoritosLotbUserLevelListing.edges[0])
    return <ErrorComponent />;

  const levels = data.getDoritosLotbUserLevelListing.edges.map(
    (level) => level.node
  );
  const reversedLevels = levels.reverse();
  const levelIndex = reversedLevels.findIndex(
    (level) => level.points <= currentPoints
  );
  const nextLevel = levelIndex === 0 ? null : reversedLevels[levelIndex - 1];
  const pointsNeeded = nextLevel ? nextLevel.points - currentPoints : 0;

  return (
    <>
      <div className='user-progress'>
        <div className='progress-total'>
          <div className='progress-next-level'></div>
          <div
            className='progress-indicator'
            style={{
              left: `${
                nextLevel ? (currentPoints / nextLevel.points) * 100 : 100
              }%`,
            }}
          >
            <ProgressIndicator />
            <span>{currentPoints} points</span>
          </div>
          <div
            style={{
              width: `${
                nextLevel ? (currentPoints / nextLevel.points) * 100 : 100
              }%`,
            }}
            className='progress-current'
          ></div>
        </div>
        <p>
          {nextLevel
            ? `${pointsNeeded} ${
                pointsNeeded === 1 ? 'point' : 'points'
              } needed to level up to ${nextLevel.tier}`
            : maximumLevelMessage}
        </p>
      </div>
    </>
  );
};

export default UserProgress;
