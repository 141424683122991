import React, { useRef, useState, useEffect } from 'react';
import { Form, Col, InputGroup, ButtonGroup } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import ChallengeSubmissionTags from './ChallengeSubmissionTags';
import ChallengeSubmissionAttachments from './ChallengeSubmissionAttachments';

import ComponentLoader from '../ComponentLoader';

const ChallengeSubmissionForm = ({
  submissionData,
  triggerUpdate,
  onCancel,
  editing,
  setEditing,
  newSubmissionId,
  allTags,
  open,
  challengeId,
  maxAttachments,
  prizeTypes,
}) => {
  const formRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [selectedTags, updateSelectedTags] = useState(null);
  const [data, setData] = useState(null);
  const [validated, setValidated] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [removedAttachments, setRemovedAttachments] = useState([]);
  const [errorMessage, setErrorMessage] = useState(
    'Sorry, there was an error saving the submission.'
  );

  useEffect(() => {
    setData(
      submissionData
        ? {
            id: submissionData.node.id,
            title: submissionData.node.title || '',
            summary: submissionData.node.summary || '',
            prizetype: submissionData.node.prizetype || 'Cash',
            attachments: submissionData.node.attachments || [],
            tags: submissionData.node.tags || [],
          }
        : {
            id: newSubmissionId,
            title: '',
            summary: '',
            prizetype: '',
            attachments: [],
            tags: [],
          }
    );
  }, []);

  useEffect(() => {
    if (!data) return;
    updateSelectedTags(data.tags === null ? [] : data.tags);
    setLoading(false);
  }, [data]);

  const submitForm = (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      // not valid
      e.preventDefault();
      e.stopPropagation();
      setHasError(true);
    } else {
      // valid
      e.preventDefault();
      setHasError(false);

      const formattedAttachments = data.attachments.map((attachment) => ({
        type: 'object',
        id: attachment.id,
      }));

      const selectedPrizeType =
        data.prizeType && data.prizeType !== ''
          ? data.prizeType
          : prizeTypes[0];

      const updatedData = {
        ...data,
        tags: selectedTags,
        attachments: formattedAttachments,
        prizetype: selectedPrizeType,
      };

      triggerUpdate(updatedData);
    }
    setValidated(true);
  };

  const cancelSubmit = () => {
    setEditing(false);
    if (removedAttachments.length) {
      const restoredAttachments = [...data.attachments, ...removedAttachments];
      setData({ ...data, attachments: restoredAttachments });
      setRemovedAttachments([]);
    }
    onCancel && onCancel();
  };

  const removeAttachment = (id) => {
    const index = data.attachments.findIndex(
      (attachment) => attachment.id === id
    );
    const removedAttachment = data.attachments[index];
    //removedAttachments.push({ ...removedAttachment });
    setRemovedAttachments([...removedAttachments, removedAttachment]);
    const _attachments = data.attachments.filter(
      (attachment) => attachment.id !== id
    );
    setData({ ...data, attachments: _attachments });
  };

  const updateData = (newData) => {
    setData({ ...data, ...newData });
  };

  const addRemoveTag = (e) => {
    const thename = e.target.dataset.name;
    if (selectedTags.indexOf(thename) > -1) {
      updateSelectedTags((list) => list.filter((item) => item !== thename));
    } else {
      updateSelectedTags((list) => [...list, thename]);
    }
  };

  if (loading || !data) return <ComponentLoader />;

  return (
    <div className={`challenge-submission-form${editing ? ' editing' : ''}`}>
      <Form
        noValidate
        validated={validated}
        onSubmit={submitForm}
        ref={formRef}
      >
        {/* TITLE */}
        <Form.Row>
          <Form.Group as={Col} controlId={`title-${data.id}`}>
            <Form.Label>title</Form.Label>
            {editing ? (
              <Form.Control
                name='title'
                required
                type='text'
                defaultValue={data.title ? data.title : ''}
                onChange={(e) => updateData({ title: e.target.value })}
              />
            ) : (
              <p>{data.title}</p>
            )}
          </Form.Group>
        </Form.Row>
        {/* SUMMARY */}
        <Form.Row>
          <Form.Group as={Col} controlId={`summary-${data.id}`}>
            <Form.Label>submission summary</Form.Label>
            {editing ? (
              <Form.Control
                //className='submission-summary'
                as='textarea'
                name='summary'
                required
                type='textarea'
                rows={5}
                defaultValue={data.summary ? data.summary : ''}
                onChange={(e) => updateData({ summary: e.target.value })}
              />
            ) : (
              <p>{data.summary}</p>
            )}
          </Form.Group>
        </Form.Row>
        {/* PRIZE TYPE */}
        {/* {(!editing || (editing && prizeTypes.length > 1)) && ( */}
        {prizeTypes.length > 1 && (
          <Form.Row>
            <Form.Group as={Col} controlId={`prizetype-${data.id}`}>
              <Form.Label>prize type</Form.Label>
              {editing ? (
                <InputGroup>
                  <Form.Control
                    name='prizetype'
                    required
                    as='select'
                    placeholder='Select Prize Type'
                    defaultValue={data.prizetype ? data.prizetype : -1}
                    onChange={(e) => updateData({ prizetype: e.target.value })}
                  >
                    {prizeTypes.map((prize, index) => (
                      <option key={index} value={prize}>
                        {prize}
                      </option>
                    ))}
                  </Form.Control>
                  <InputGroup.Append></InputGroup.Append>
                </InputGroup>
              ) : (
                <p>{data.prizetype}</p>
              )}
            </Form.Group>
          </Form.Row>
        )}
        <Form.Row>
          <Form.Group as={Col} controlId={`attachments-${data.id}`}>
            <Form.Label className='attachments-label'>
              Attachments
              <p className='max-attachments'>(Max. {maxAttachments})</p>
            </Form.Label>
            <ChallengeSubmissionAttachments
              attachments={data.attachments}
              editing={editing}
              removeAttachment={removeAttachment}
              challengeId={challengeId}
              submissionId={parseInt(data.id)}
              maxAttachments={maxAttachments}
            />
          </Form.Group>
        </Form.Row>
        <Form.Row className='tags-row'>
          <Form.Group as={Col} controlId={`tags-${data.id}`}>
            <Form.Label>Tags</Form.Label>
            <ChallengeSubmissionTags
              tags={allTags}
              selectedTags={selectedTags}
              addRemoveTag={addRemoveTag}
              editing={editing}
            />
          </Form.Group>
        </Form.Row>
      </Form>

      {/* BUTTONS */}
      {editing && (
        <>
          <Button
            variant='primary'
            className={hasError ? 'error' : ''}
            onClick={submitForm}
          >
            {hasError ? 'Error' : 'Submit'}
          </Button>
          <Button variant='primary' onClick={cancelSubmit}>
            Cancel
          </Button>
        </>
      )}
      {!editing && open && (
        <Button variant='primary' onClick={() => setEditing(true)}>
          Edit
        </Button>
      )}
    </div>
  );
};

export default ChallengeSubmissionForm;
