import { gql } from '@apollo/client';

export const GET_CONTENT = gql`
query getDoritosLotbContentListing($filter: String!) {
  getDoritosLotbContentListing (
    defaultLanguage: "en"
    filter: $filter
  ) {
    edges {
      node {
        slug
        title
        tag
        contentType
        content
        metadata {
          key
          value
        }
        image {
          ... on object_DoritosLotbImage {
            title
            slug
            alt
            image {
              fullpath
            }
            width
            height
            mobileImage {
              fullpath
            }
            mobileWidth
            mobileHeight
          }
        }
        video {
          ... on asset {
            fullpath
          }
        }
      }
    }
  }
}
`;
