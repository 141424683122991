import { gql } from '@apollo/client';

export const GET_AVATARS = gql`
  {
    getDoritosLotbAvatarListing(defaultLanguage: "en") {
      totalCount
      edges {
        node {
          id
          name
          image {
            id
            filename
            fullpath
          }
        }
      }
    }
  }
`;

export const GET_HEADERS = gql`
  {
    getDoritosLotbProfileHeaderListing(defaultLanguage: "en") {
      totalCount
      edges {
        node {
          id
          name
          image {
            id
            filename
            fullpath
          }
        }
      }
    }
  }
`;

export const GET_FAVORITES = gql`
  {
    getDoritosLotbFlavorListing(defaultLanguage: "en") {
      totalCount
      edges {
        node {
          id
          name
        }
      }
    }
    getDoritosLotbPlatformListing(defaultLanguage: "en") {
      totalCount
      edges {
        node {
          id
          name
        }
      }
    }
    getDoritosLotbSizeListing(
      defaultLanguage: "en"
      sortBy: "name"
      sortOrder: "desc"
    ) {
      totalCount
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

export const GET_ALL_INTERESTS = gql`
  {
    getDoritosLotbInterestListing(defaultLanguage: "en") {
      totalCount
      edges {
        node {
          name
        }
      }
    }
  }
`;

export const GET_USER_LEVELS = gql`
  {
    getDoritosLotbUserLevelListing(defaultLanguage: "en") {
      edges {
        node {
          tier
          points
        }
      }
    }
  }
`;
