import React, { useRef, useEffect } from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { NavLink, useLocation } from 'react-router-dom';
import LoginButton from './LoginButton';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import logo from '../assets/svg/lotb-logo.svg';

const Navigation = (data, props) => {
  const location = useLocation();
  const logoRef = useRef(null);
  const loginContainerRef = useRef(null);
  const navbarNavRef = useRef(null);
  const navbarBgRef = useRef(null);

  gsap.registerPlugin(ScrollTrigger);

  useEffect(() => {
    if (location.pathname.includes('setup')) return;
    const logo = logoRef.current;
    const loginLink = loginContainerRef.current;
    const navbarNav = navbarNavRef.current;
    const navbarBg = navbarBgRef.current;

    let t1 = gsap.timeline({
      scrollTrigger: {
        trigger: document.getElementsByClassName('.hero')[0],
        start: 'top top',
        end: '+=100',
        scrub: true,
      },
    });
    t1.add('stop')
      .to(
        logo,
        {
          scale: 0.8,
          y: -15,
        },
        'start'
      )
      .to(
        loginLink,
        {
          y: -15,
        },
        'start'
      )
      .to(
        navbarNav,
        {
          y: -20,
        },
        'start'
      )
      .to(
        navbarBg,
        {
          opacity: 1,
        },
        'start'
      );
  }, []);

  return (
    <Navbar className='nav'>
      <div className='navbar-background' ref={navbarBgRef}></div>
      {!location.pathname.includes('setup') && (
        <div className='login-button-container' ref={loginContainerRef}>
          <LoginButton metadata={data.metadata} />
        </div>
      )}
      <LinkContainer to='/'>
        <Navbar.Brand ref={logoRef}>
          <img alt='logo' src={logo} />
        </Navbar.Brand>
      </LinkContainer>
      {!location.pathname.includes('setup') && (
        <Nav
          ref={navbarNavRef}
          activeKey={window.location.pathname}
          className='mr-auto'
        >
          <Nav.Item>
            <NavLink exact to={'/all-activity'}>
              All Activity
            </NavLink>
          </Nav.Item>
          <Nav.Item>
            <NavLink exact to={'/challenges'}>
              Challenges
            </NavLink>
          </Nav.Item>
          <Nav.Item>
            <NavLink exact to={'/winners'}>
              Winners
            </NavLink>
          </Nav.Item>
        </Nav>
      )}
    </Navbar>
  );
};

export default Navigation;
