import React from 'react';
import logo from '../assets/svg/lotb-logo.svg';

const MaintenanceComponent = ({ message }) => {
  return (
    <div className='maintenance-component'>
      <img alt='logo' src={logo} />
      <h1>{message}</h1>
    </div>
  );
};

export default MaintenanceComponent;
