import { gql } from '@apollo/client';

export const METADATA_ADD = gql`
mutation addMetaData(
  $id: Int!
  $uid: Int!
  $activity: String!
) {
  addMetaData(
    input: {
      activity: $activity
      user_id: $uid
      object_id: $id
    }
  ) {
    status
    activity
    user_id
    object_id
  }
}
`;
