import { InMemoryCache } from '@apollo/client';
import { userData, userLoggedIn } from './reactivities/user';
import { siteLoading } from './reactivities/site';

// Here ,let's define our type and field poliies for our reactive variable.

export default new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        userData: {
          read() {
            return userData();
          },
        },
        userLoggedIn: {
          read() {
            return userLoggedIn();
          },
        },
        siteLoading: {
          read() {
            return siteLoading();
          },
        },
      },
    },
  },
});
