import React from 'react';
import HomeComponent from '../components/home/Home';
import { useQuery } from '@apollo/client';
import { GET_CONTENT } from '../graphql/queries/content.js';
import PageLoader from '../components/PageLoader';

const Home = ({ metadata }) => {
  const { data, loading, error } = useQuery(GET_CONTENT, {
    variables: { filter: `{"tag": "home"}`, fetchPolicy: 'no-cache' },
  });

  if (loading) return <PageLoader />;
  if (error) return <p>ERROR</p>;
  if (!data || !data.getDoritosLotbContentListing.edges[0])
    return <PageLoader />;

  const homepage = data.getDoritosLotbContentListing.edges.reduce(function (
    map,
    obj
  ) {
    map[obj.node.slug] = obj.node;
    return map;
  },
  {});

  return (
    <>
      <HomeComponent homepage={homepage} />
    </>
  );
};

export default Home;
