import React from 'react';
import { Helmet } from 'react-helmet';
import EditUserComponent from '../components/edit-user/EditUser';
import { useQuery } from '@apollo/client';
import { GET_CONTENT } from '../graphql/queries/content';
import PageLoader from '../components/PageLoader';
import { formatContentData } from '../Helpers';
import ErrorComponent from '../components/ErrorComponent';

const EditUser = ({ username }) => {
  const { data, loading, error } = useQuery(GET_CONTENT, {
    variables: { filter: `{"tag": "profile"}`, fetchPolicy: 'no-cache' },
  });

  if (loading || !data) return <PageLoader />;
  if (error || !data.getDoritosLotbContentListing.edges[0])
    return <ErrorComponent />;

  const setup = data.getDoritosLotbContentListing.edges.reduce(function (
    map,
    obj
  ) {
    map[obj.node.slug] = obj.node;
    return map;
  },
  {});

  const pageData = formatContentData(setup, 'edit-profile');

  return (
    <>
      <Helmet>
        <title> - Edit Profile</title>
      </Helmet>
      <EditUserComponent pageData={pageData} username={username} />
    </>
  );
};

export default EditUser;
