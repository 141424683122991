import React from 'react';
import { Link } from 'react-router-dom';
import { useReactiveVar } from '@apollo/client';
import { siteLoading } from '../graphql/reactivities/site';

import doritosLogo from '../assets/svg/doritos-logo.svg';

import facebookIcon from '../assets/img/footer-facebook.png';
import instagramIcon from '../assets/img/footer-instagram.png';
import pinterestIcon from '../assets/img/footer-pinterest.png';
import snapchatIcon from '../assets/img/footer-snapchat.png';
import tiktokIcon from '../assets/img/footer-tiktok.png';
import twitterIcon from '../assets/img/footer-twitter.png';
import youtubeIcon from '../assets/img/footer-youtube.png';

const Footer = (data) => {
  const siteIsLoading = useReactiveVar(siteLoading);
  if (siteIsLoading) return <></>;
  return (
    <>
      <div className='footer'>
        <img alt='doritos logo' className='doritos-logo' src={doritosLogo} />
        <div className='footer-items'>
          <ul>
            <li>
              <a
                tabIndex='0'
                href={data.metadata.howItWorksPDF}
                rel='noreferrer'
                target='_blank'
              >
                how it works.{' '}
                <span className='sr-only'>
                  Opens How it Works PDF in a new window
                </span>
              </a>
            </li>
            <li>
              <a
                tabIndex='0'
                href={`mailto:${data.metadata.contactEmail}`}
                rel='noreferrer'
                target='_blank'
              >
                contact us.{' '}
                <span className='sr-only'>
                  Opens contact email in a new window
                </span>
              </a>
            </li>
            <li>
              <a
                tabIndex='0'
                href={data.metadata.privacyPolicyURL}
                rel='noreferrer'
                target='_blank'
              >
                privacy policy.{' '}
                <span className='sr-only'>
                  Opens Privacy Policy in a new window
                </span>
              </a>
            </li>
            <li>
              <Link to='/terms'>TERMS OF USE.</Link>
            </li>
            <li>
              <div id="teconsent"></div>
            </li>
            <li>
              <span>{data.metadata.copyright}</span>
            </li>
          </ul>
          <ul>
            {data.metadata.facebookURL && (
              <li>
                <a
                  rel='noreferrer'
                  href={data.metadata.facebookURL}
                  target='_blank'
                >
                  <img src={facebookIcon} alt='facebook share' />
                </a>
              </li>
            )}
            {data.metadata.instagramURL && (
              <li>
                <a
                  rel='noreferrer'
                  href={data.metadata.instagramURL}
                  target='_blank'
                >
                  <img src={instagramIcon} alt='instagram share' />
                </a>
              </li>
            )}
            {data.metadata.pinterestURL && (
              <li>
                <a
                  rel='noreferrer'
                  href={data.metadata.pinterestURL}
                  target='_blank'
                >
                  <img src={pinterestIcon} alt='pinterest share' />
                </a>
              </li>
            )}
            {data.metadata.snapchatURL && (
              <li>
                <a
                  rel='noreferrer'
                  href={data.metadata.snapchatURL}
                  target='_blank'
                >
                  <img src={snapchatIcon} alt='snapchat share' />
                </a>
              </li>
            )}
            {data.metadata.tiktokURL && (
              <li>
                <a
                  rel='noreferrer'
                  href={data.metadata.tiktokURL}
                  target='_blank'
                >
                  <img src={tiktokIcon} alt='tiktok share' />
                </a>
              </li>
            )}
            {data.metadata.twitterURL && (
              <li>
                <a
                  rel='noreferrer'
                  href={data.metadata.twitterURL}
                  target='_blank'
                >
                  <img src={twitterIcon} alt='twitter share' />
                </a>
              </li>
            )}
            {data.metadata.youtubeURL && (
              <li>
                <a
                  rel='noreferrer'
                  href={data.metadata.youtubeURL}
                  target='_blank'
                >
                  <img src={youtubeIcon} alt='youtube share' />
                </a>
              </li>
            )}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Footer;
