import React, { useState, useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import BackgroundImage from '../BackgroundImage';
import Button from 'react-bootstrap/Button';
import { Form, Col } from 'react-bootstrap';

import { useReactiveVar } from '@apollo/client';
import { userLogInData } from '../../graphql/reactivities/user';

import { useMutation } from '@apollo/client';
import { UPDATE_SOCIALS } from '../../graphql/mutations/user.js';
import ErrorComponent from '../ErrorComponent';

const SocialsScreen = ({ setup }) => {
  let history = useHistory();

  const formRef = useRef(null);

  const emptyData = {
    facebook: '',
    behance: '',
    instagram: '',
    linkedin: '',
    twitter: '',
    youtube: '',
    twitch: '',
    tiktok: '',
    snapchat: '',
  };

  const userLogIn = useReactiveVar(userLogInData);
  const [data, setData] = useState({ ...emptyData });

  const [validated, setValidated] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(
    'Sorry, there was an error.'
  );
  let numErrors = 0;

  // UPDATE USER MUTATION
  const [updateUser] = useMutation(UPDATE_SOCIALS, {
    onCompleted: (data) => {
      if (data.updateDoritosLotbUser.success === true) {
        history.push('/setup/7');
      } else {
        numErrors++;
        if (numErrors > 1) {
          return <ErrorComponent />;
        } else {
          setHasError(true);
        }
      }
    },
    onError: (error) => {
      numErrors++;
      if (numErrors > 1) {
        return <ErrorComponent />;
      } else {
        setHasError(true);
      }
    },
  });

  useEffect(() => {
    formRef.current.addEventListener('click', () => {
      setHasError(false);
    });
  }, []);

  const submitForm = (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      // not valid
      e.preventDefault();
      e.stopPropagation();
      setHasError(true);
    } else {
      // valid
      e.preventDefault();
      setHasError(false);
      saveSocials();
    }
    setValidated(true);
  };

  const saveSocials = (skip = false) => {
    let _data;
    if (skip) {
      _data = {
        id: userLogIn.id,
        ...emptyData,
      };
    } else {
      _data = {
        id: userLogIn.id,
        ...data,
      };
    }
    updateUser({
      variables: _data,
    });
  };

  const onURLChange = (e) => {
    const platform = e.target.dataset.platform;
    setData({ ...data, [platform]: e.target.value });
  };

  return (
    <>
      <div className='background-image-container'>
        <BackgroundImage data={setup.image.background} />
      </div>
      <div className='content socials-screen'>
        <div className='content-holder'>
          <h2>{setup.metadata.header}</h2>

          {hasError && <p className='error'>{errorMessage}</p>}

          <Form
            noValidate
            validated={validated}
            onSubmit={submitForm}
            ref={formRef}
          >
            <Form.Row>
              <Form.Group as={Col} controlId='twitter'>
                <Form.Label>twitter profile URL</Form.Label>
                <Form.Control
                  name='twitter'
                  type='text'
                  data-platform='twitter'
                  pattern='^https:\/\/(.*)'
                  onChange={onURLChange}
                />
                <p className='invalid-feedback'>
                  Profile URL must start with 'https://'
                </p>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} controlId='youtube'>
                <Form.Label>youtube channel URL</Form.Label>
                <Form.Control
                  name='youtube'
                  type='text'
                  data-platform='youtube'
                  pattern='^https:\/\/(.*)'
                  onChange={onURLChange}
                />
                <p className='invalid-feedback'>
                  Profile URL must start with 'https://'
                </p>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} controlId='instagram'>
                <Form.Label>instagram Profile URL</Form.Label>
                <Form.Control
                  name='instagram'
                  type='text'
                  data-platform='instagram'
                  pattern='^https:\/\/(.*)'
                  onChange={onURLChange}
                />
                <p className='invalid-feedback'>
                  Profile URL must start with 'https://'
                </p>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} controlId='linkedin'>
                <Form.Label>linkedin profile URL</Form.Label>
                <Form.Control
                  name='linkedin'
                  type='text'
                  data-platform='linkedin'
                  pattern='^https:\/\/(.*)'
                  onChange={onURLChange}
                />
                <p className='invalid-feedback'>
                  Profile URL must start with 'https://'
                </p>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} controlId='tiktok'>
                <Form.Label>tiktok profile URL</Form.Label>
                <Form.Control
                  name='tiktok'
                  type='text'
                  data-platform='tiktok'
                  pattern='^https:\/\/(.*)'
                  onChange={onURLChange}
                />
                <p className='invalid-feedback'>
                  Profile URL must start with 'https://'
                </p>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} controlId='behance'>
                <Form.Label>behance profile URL</Form.Label>
                <Form.Control
                  name='behance'
                  type='text'
                  data-platform='behance'
                  pattern='^https:\/\/(.*)'
                  onChange={onURLChange}
                />
                <p className='invalid-feedback'>
                  Profile URL must start with 'https://'
                </p>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} controlId='facebook'>
                <Form.Label>facebook profile URL</Form.Label>
                <Form.Control
                  name='facebook'
                  type='text'
                  data-platform='facebook'
                  pattern='^https:\/\/(.*)'
                  onChange={onURLChange}
                />
                <p className='invalid-feedback'>
                  Profile URL must start with 'https://'
                </p>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} controlId='twitch'>
                <Form.Label>twitch profile URL</Form.Label>
                <Form.Control
                  name='twitch'
                  type='text'
                  data-platform='twitch'
                  pattern='^https:\/\/(.*)'
                  onChange={onURLChange}
                />
                <p className='invalid-feedback'>
                  Profile URL must start with 'https://'
                </p>
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} controlId='snapchat'>
                <Form.Label>snapchat profile URL</Form.Label>
                <Form.Control
                  name='snapchat'
                  type='text'
                  data-platform='snapchat'
                  pattern='^https:\/\/(.*)'
                  onChange={onURLChange}
                />
                <p className='invalid-feedback'>
                  Profile URL must start with 'https://'
                </p>
              </Form.Group>
            </Form.Row>

            <Button
              type='submit'
              variant='primary'
              className={hasError ? 'error' : ''}
            >
              {hasError ? 'Error' : 'save & continue'}
            </Button>
          </Form>
          <div className='next-prev-buttons'>
            {/* <span
              onClick={(e) => {
                e.preventDefault();
                history.goBack();
              }}
            >
              Back
            </span> */}
            <span
              onClick={(e) => {
                e.preventDefault();
                saveSocials(true);
                //history.push('/setup/7');
              }}
            >
              Skip
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default SocialsScreen;
