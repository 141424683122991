import React, { useEffect, useState } from 'react';
import nodeUrl from 'url';
import cookie from 'js-cookie';
import { useHistory, Link } from 'react-router-dom';
import { buildSignInURL } from '../Helpers';
import { useApolloClient, useLazyQuery, useMutation } from '@apollo/client';
import { useReactiveVar } from '@apollo/client';
import { userLogInData, userLoggedIn } from '../graphql/reactivities/user';
import { siteLoading } from '../graphql/reactivities/site';
import { USER_LOGIN, USER_LOGOUT } from '../graphql/queries/user.js';
import NotificationModal from './NotificationModal';
import { CLEAR_NOTIFICATION } from '../graphql/mutations/user';

const LoginButton = (data, props) => {
      
  const history = useHistory();

  const user = useReactiveVar(userLogInData);
  const loggedIn = useReactiveVar(userLoggedIn);

  const client = useApolloClient();

  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState('');
  const [modalMessage, setModalMessage] = useState('');

  const [userLogout] = useLazyQuery(USER_LOGOUT, {
    fetchPolicy: 'network-only',
    onCompleted: (data) => logOut(data),
    onError: (data) => logOut(data),
  });

  useEffect(() => {
    // CHECK FOR AUTHENTICATION HASH
    if (window.location.hash.includes('id_token')) {
      siteLoading(true);
      const url = nodeUrl.parse(window.location.href);
      const hash = nodeUrl.parse('?' + url.hash.substring(1), true);
      cookie.set('path', hash.query.state);
      window.location.hash = '';
      awaitLogin(hash.query.id_token);
    } else if (localStorage.getItem('user')) {
      loginSuccess(JSON.parse(localStorage.getItem('user')));
    }
  }, []);

  const awaitLogin = async (token) => {
    const { data, error } = await client.query({
      query: USER_LOGIN,
      variables: { token: token },
    });
    if (error) {
      loginError(error);
    } else {
      loginSuccess(data.userLogin);
    }
  };

  function logIn(e) {
    e.preventDefault();
    window.location.href = buildSignInURL(data.metadata.loginURL);
  }

  function loginSuccess(userLogin) {
    userLoggedIn(true);
    userLogInData(userLogin);
    localStorage.setItem('user', JSON.stringify(userLogin));

    checkNotifications(userLogin);

    if (!userLogin.setup) {
      history.push('/setup/1');
    } else if (cookie.get('path') !== '/') {
      history.push(cookie.get('path'));
    } else {
      //history.push('/all-activity');
    }

    cookie.remove('path');
    siteLoading(false);
  }

  function loginError(data) {
    userLoggedIn(false);
    userLogInData(null);
    siteLoading(false);
  }

  function logOut(data) {
    userLoggedIn(false);
    userLogInData(null);
    localStorage.clear();
    history.push('/');
  }

  const checkNotifications = (_user) => {
    if (_user.notifications.length) {
      // notifications are present
      setModalTitle(_user.notifications[0].title);
      setModalMessage(_user.notifications[0].message);
      setShowModal(true);
    }
  };

  const [clearNotification] = useMutation(CLEAR_NOTIFICATION, {
    onCompleted: (data) => {
      checkNotifications(user);
    },
    onError: (error) => {
      checkNotifications(user);
    },
  });

  const handleClose = (e) => {
    setShowModal(false);

    if (e) {
      // OK button clicked (dismiss)
      const notification = user.notifications[0];
      const updatedNotifications = user.notifications.filter(
        (_notification) => _notification.nid !== notification.nid
      );
      const newUser = { ...user, notifications: updatedNotifications };
      userLogInData(newUser);
      localStorage.setItem('user', JSON.stringify(newUser));
      clearNotification({
        variables: {
          uid: user.id,
          nid: notification.nid,
        },
      });
    }
  };

  const setup = user ? user.setup : false;
  const username = user ? user.username : '';
  const userControl = !setup ? (
    <span>
      Welcome! <Link to='/setup/1'>Create Your Profile</Link>
    </span>
  ) : (
    <span>
      Welcome Back, <Link to={`/users/${username}`}>{username}</Link>
    </span>
  );

  return (
    <>
      <div className={`login-button${loggedIn ? ' loggedIn' : ''}`}>
        {loggedIn === true ? (
          <p>
            {userControl}
            <span className='logout' onClick={userLogout}>
              LOGOUT
            </span>
          </p>
        ) : (
          <p>
            <span className='login-link' onClick={logIn}>
              SIGN IN
            </span>
          </p>
        )}
      </div>
      <NotificationModal
        show={showModal}
        title={modalTitle}
        message={modalMessage}
        handleClose={handleClose}
        buttonText={'OK'}
      />
    </>
  );
};

export default LoginButton;
