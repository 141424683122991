import React from 'react';

const BackgroundVideo = ({ url }) => {
  return (
    <div className='background-video'>
      <video aria-hidden="true" autoPlay muted loop>
        <source src={url} type='video/mp4'></source>
      </video>
    </div>
  );
};

export default BackgroundVideo;
