import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import BackgroundImage from '../BackgroundImage';
import Button from 'react-bootstrap/Button';
import { Form, Col } from 'react-bootstrap';

import { useReactiveVar } from '@apollo/client';
import { userLogInData } from '../../graphql/reactivities/user';

import { UPDATE_USERNAME } from '../../graphql/mutations/user.js';
import { useMutation } from '@apollo/client';
import ErrorComponent from '../ErrorComponent';
import PageLoader from '../PageLoader';

const UsernameScreen = ({ setup }) => {
  let history = useHistory();

  const formRef = useRef(null);
  const userLogIn = useReactiveVar(userLogInData);

  const [notificationsSelected, setNotificationsSelected] = useState(false);
  const [username, setUsername] = useState('');
  const [validated, setValidated] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [hasUsernameError, setHasUsernameError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(
    'Sorry, there was an error.'
  );
  const [usernameSet, setUsernameSet] = useState(false);
  const [loading, setLoading] = useState(true);
  let numErrors = 0;

  useEffect(() => {
    const localStorageUserData = JSON.parse(localStorage.getItem('user'));
    if (localStorageUserData.setup) {
      setUsername(localStorageUserData.username);
      setNotificationsSelected(localStorageUserData.optin);
      setUsernameSet(true);
    }
    setLoading(false);
  }, []);

  useEffect(() => {
    if (loading) return;
    formRef.current.addEventListener('click', () => {
      setHasError(false);
      setHasUsernameError(false);
      setValidated(false);
    });
  }, []);

  const submitForm = (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      // not valid
      e.preventDefault();
      e.stopPropagation();
      setHasError(true);
    } else {
      // valid
      e.preventDefault();
      setHasError(false);
      updateUser({
        variables: {
          id: userLogIn.id,
          username: username,
          optin: notificationsSelected,
          setup: true,
        },
      });
    }
    setValidated(true);
  };

  // UPDATE USER MUTATION
  const [updateUser] = useMutation(UPDATE_USERNAME, {
    onCompleted: (data) => {
      if (data.updateDoritosLotbUser.success == true) {
        const _userLogIn = { ...userLogIn };
        _userLogIn.setup = true;
        _userLogIn.username = username;
        _userLogIn.optin = notificationsSelected;
        userLogInData(_userLogIn);
        localStorage.setItem('user', JSON.stringify(_userLogIn));
        history.push('/setup/2');
      } else {
        numErrors++;
        if (numErrors > 1) {
          return <ErrorComponent />;
        } else {
          setErrorMessage(setup.metadata.errorMessage);
          setHasUsernameError(true);
        }
      }
    },
    onError: (error) => {
      numErrors++;
      if (numErrors > 1) {
        return <ErrorComponent />;
      } else {
        setErrorMessage(setup.metadata.errorMessage + ' -- ' + error);
        setHasUsernameError(true);
      }
    },
  });

  if (loading) return <PageLoader />;

  return (
    <>
      <div className='background-image-container'>
        <BackgroundImage data={setup.image.background} />
      </div>
      <div className='content username-screen'>
        <div className='content-holder'>
          <h2>{setup.metadata.header}</h2>
          {hasUsernameError && <p className='error'>{errorMessage}</p>}
          <Form
            noValidate
            validated={validated}
            onSubmit={submitForm}
            ref={formRef}
          >
            <Form.Row>
              <Form.Group as={Col} controlId='username'>
                <Form.Label>Username</Form.Label>
                {usernameSet ? (
                  <Form.Control
                    type='text'
                    defaultValue={username || ''}
                    disabled
                  />
                ) : (
                  <Form.Control
                    name='username'
                    required
                    type='text'
                    pattern='^([a-zA-Z0-9][a-zA-Z0-9_]{0,31}|)$'
                    maxLength={32}
                    defaultValue={username}
                    onChange={(e) => setUsername(e.target.value)}
                  />
                )}
                <p className='invalid-feedback'>
                  Username must start with a letter or number
                </p>
              </Form.Group>
            </Form.Row>
            <div className='optins'>
              <div className='optin'>
                <Form.Group>
                  {usernameSet ? (
                    <input
                      id='optin-1'
                      className='checkbox'
                      tabIndex='0'
                      type='checkbox'
                      required
                      checked
                      readOnly
                    />
                  ) : (
                    <input
                      id='optin-1'
                      className='checkbox'
                      tabIndex='0'
                      type='checkbox'
                      required
                    />
                  )}

                  <span className='checkbox-checkmark'></span>
                  <label htmlFor='optin-1'>{setup.metadata.optinCitizen}</label>
                </Form.Group>
              </div>
              <div className='optin'>
                <Form.Group>
                  {usernameSet ? (
                    <input
                      id='optin-2'
                      className='checkbox'
                      tabIndex='0'
                      type='checkbox'
                      required
                      checked
                      readOnly
                    />
                  ) : (
                    <input
                      id='optin-2'
                      className='checkbox'
                      tabIndex='0'
                      type='checkbox'
                      required
                    />
                  )}

                  <span className='checkbox-checkmark'></span>
                  <label
                    dangerouslySetInnerHTML={{
                      __html: setup.metadata.optinTerms,
                    }}
                    htmlFor='optin-2'
                  ></label>
                </Form.Group>
              </div>
              <div className='optin'>
                <Form.Group>
                  <input
                    id='optin-3'
                    className='checkbox'
                    tabIndex='0'
                    type='checkbox'
                    checked={notificationsSelected}
                    onChange={() =>
                      setNotificationsSelected(!notificationsSelected)
                    }
                  />
                  <span className='checkbox-checkmark'></span>
                  <label htmlFor='optin-3'>
                    {setup.metadata.optinNotifications}
                  </label>
                </Form.Group>
              </div>
            </div>
            <Button
              type='submit'
              variant='primary'
              className={hasError ? 'error' : ''}
            >
              {hasError ? 'Error' : 'save & continue'}
            </Button>
          </Form>
        </div>
      </div>
    </>
  );
};

export default UsernameScreen;
