import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import BackgroundImage from '../BackgroundImage';
import Button from 'react-bootstrap/Button';
import { Form, Col, InputGroup } from 'react-bootstrap';

import { useReactiveVar } from '@apollo/client';
import { userLogInData } from '../../graphql/reactivities/user';

import { useMutation } from '@apollo/client';
import { UPDATE_ADDRESS } from '../../graphql/mutations/user.js';

import { states } from '../../assets/data/states.json';
import ErrorComponent from '../ErrorComponent';

const AddressScreen = ({ setup }) => {
  let history = useHistory();

  const formRef = useRef(null);

  const userLogIn = useReactiveVar(userLogInData);
  const [firstname, setFirstname] = useState('');
  const [lastname, setLastname] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zip, setZip] = useState('');

  const [validated, setValidated] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(
    'Sorry, there was an error.'
  );
  let numErrors = 0;

  // UPDATE USER MUTATION
  const [updateUser] = useMutation(UPDATE_ADDRESS, {
    onCompleted: (data) => {
      if (data.updateDoritosLotbUser.success === true) {
        history.push(`/users/${userLogIn.username}/edit`);
      } else {
        numErrors++;
        if (numErrors > 1) {
          return <ErrorComponent />;
        } else {
          setErrorMessage('Sorry, there was an error');
          setHasError(true);
        }
      }
    },
    onError: (error) => {
      numErrors++;
      if (numErrors > 1) {
        return <ErrorComponent />;
      } else {
        setErrorMessage('Sorry, there was an error');
        setHasError(true);
      }
    },
  });

  useEffect(() => {
    formRef.current.addEventListener('click', () => {
      setHasError(false);
      setValidated(false);
    });
  }, []);

  const submitForm = (e) => {
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      // not valid
      e.preventDefault();
      e.stopPropagation();
      setErrorMessage('All fields are required');
      setHasError(true);
    } else {
      // valid
      e.preventDefault();
      setHasError(false);
      saveAddress();
    }
    setValidated(true);
  };

  const saveAddress = () => {
    updateUser({
      variables: {
        id: userLogIn.id,
        firstname: firstname,
        lastname: lastname,
        address: address,
        city: city,
        state: state,
        zip: zip,
      },
    });
  };

  return (
    <>
      <div className='background-image-container'>
        <BackgroundImage data={setup.image.background} />
      </div>
      <div className='content address-screen'>
        <div className='content-holder'>
          <h2>{setup.metadata.header}</h2>

          {hasError ? (
            <p className='error'>{errorMessage}</p>
          ) : (
            <p>All fields are required</p>
          )}

          <Form
            noValidate
            validated={validated}
            onSubmit={submitForm}
            ref={formRef}
          >
            <Form.Row>
              <Form.Group as={Col} xs='6' controlId='firstname'>
                <Form.Label>first name</Form.Label>
                <Form.Control
                  name='firstname'
                  required
                  type='text'
                  maxLength={20}
                  onChange={(e) => setFirstname(e.target.value)}
                />
              </Form.Group>
              <Form.Group as={Col} xs='6' controlId='lastname'>
                <Form.Label>last name</Form.Label>
                <Form.Control
                  name='lastname'
                  required
                  maxLength={40}
                  type='text'
                  onChange={(e) => setLastname(e.target.value)}
                />
              </Form.Group>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId='address'>
                <Form.Label>address</Form.Label>
                <Form.Control
                  name='address'
                  required
                  maxLength={190}
                  type='text'
                  onChange={(e) => setAddress(e.target.value)}
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} controlId='city'>
                <Form.Label>city</Form.Label>
                <Form.Control
                  name='city'
                  required
                  maxLength={60}
                  type='text'
                  onChange={(e) => setCity(e.target.value)}
                />
              </Form.Group>
            </Form.Row>

            <Form.Row>
              <Form.Group as={Col} xs='8' controlId='state'>
                <Form.Label>state</Form.Label>
                <InputGroup>
                  <Form.Control
                    name='state'
                    required
                    as='select'
                    placeholder='Select State'
                    onChange={(e) => setState(e.target.value)}
                  >
                    {states.map((state, index) => (
                      <option key={index} value={state.abbreviation}>
                        {state.name}
                      </option>
                    ))}
                  </Form.Control>
                  <InputGroup.Append></InputGroup.Append>
                </InputGroup>
              </Form.Group>
              <Form.Group as={Col} xs='4' controlId='zip'>
                <Form.Label>zip</Form.Label>
                <Form.Control
                  name='zip'
                  required
                  type='text'
                  maxLength={5}
                  onChange={(e) => setZip(e.target.value)}
                />
              </Form.Group>
            </Form.Row>

            <Button
              type='submit'
              variant='primary'
              className={hasError ? 'error' : ''}
            >
              {hasError ? 'Error' : 'save & continue'}
            </Button>

            {/* <div className='next-prev-buttons'>
              <span
                onClick={(e) => {
                  e.preventDefault();
                  history.goBack();
                }}
              >
                Back
              </span>
            </div> */}
          </Form>
        </div>
      </div>
    </>
  );
};

export default AddressScreen;
