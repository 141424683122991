import React, { useState, useRef } from 'react';
import VisionBoard from '../VisionBoard';
import Hero from './Hero';
import FeaturedChallenge from './FeaturedChallenge';
import FeaturedCreator from './FeaturedCreator';
import { formatContentData } from '../../Helpers';
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { GET_HOMEPAGE_BOARD } from '../../graphql/queries/visionboard.js';

const HomeComponent = ({ homepage }) => {
  const nextSectionRef = useRef(null);

  const [queryGQL, setQueryGQL] = useState(GET_HOMEPAGE_BOARD);
  const [queryObject, setQueryObject] = useState('getDoritosLotbLookupListing');
  const [queryVariables, setQueryVariables] = useState({
    filter: `{"$and":[{"featured": true},{"lookuptype": "challenge"}]}`,
    sort: 'sortdate',
    order: 'desc',
  });

  gsap.registerPlugin(ScrollToPlugin);

  const triggerScrollDown = () => {
    gsap.to(window, {
      scrollTo: nextSectionRef.current.offsetTop - 100,
    });
  };

  const hero = formatContentData(homepage, 'homepage-cta');
  const challenge = homepage['featured-challenge']
    ? formatContentData(homepage, 'featured-challenge')
    : null;
  const creator = homepage['featured-creator']
    ? formatContentData(homepage, 'featured-creator')
    : null;

  return (
    <>
      <section className='home'>
        <Hero
          heading={hero.title}
          intro={hero.content}
          buttonText={hero.metadata.button}
          buttonURL={hero.metadata.url}
          searchDisplay={hero.metadata.search}
          searchText={hero.metadata.searchText}
          backgroundImage={hero.image.background}
          backgroundVideo={hero.video ? hero.video.fullpath : null}
          triggerScrollDown={triggerScrollDown}
          metadata={hero.metadata}
        />
        <div className='featured-challenge-holder' ref={nextSectionRef}>
          {challenge && (
            <FeaturedChallenge
              title={challenge.title}
              backgroundImage={challenge.image.background}
              description={challenge.content}
              buttonText={challenge.metadata.button}
              slug={challenge.metadata.slug}
              borderColor={challenge.metadata.borderColor}
              metadata={challenge.metadata}
            />
          )}
        </div>

        <VisionBoard
          query={queryGQL}
          object={queryObject}
          variables={queryVariables}
          className='show-background'
        />

        {creator && (
          <FeaturedCreator
            heading={creator.metadata.header}
            name={creator.title}
            backgroundImage={creator.image.creator}
            buttonText={creator.metadata.button}
            description={creator.content}
            username={creator.metadata.username}
            metadata={creator.metadata}
          />
        )}
      </section>
    </>
  );
};

export default HomeComponent;
