import React from 'react';
import { Helmet } from 'react-helmet';
import UserComponent from '../components/user/User';

const User = (props) => {
  return (
    <>
      <Helmet>
        <title> - {props.username}</title>
      </Helmet>
      <UserComponent username={props.username} metadata={props.metadata} />
    </>
  );
};

export default User;
