import React from 'react';
import { Button } from 'react-bootstrap';
import BackgroundImage from '../BackgroundImage';
import { useHistory } from 'react-router-dom';

import { ReactComponent as HollowChip } from '../../assets/svg/hollow-chip.svg';
import doubleChips from '../../assets/img/double-chips.png';

const FeaturedCreator = (props) => {
  const history = useHistory();

  const buttonClick = () => {
    if (props.metadata.challenge) {
      history.push(`/challenges/${props.metadata.challenge}`);
    } else if (props.metadata.winner) {
      history.push(`/winners/${props.metadata.winner}`);
    } else if (props.metadata.username) {
      history.push(`/users/${props.metadata.username}`);
    } else if (props.metadata.url) {
      if (props.metadata.newWindow) {
        window.open(props.metadata.url);
      } else {
        window.location.href = props.metadata.url;
      }
    }
  };

  return (
    <section className='featured-creator'>
      <div className='graphics'>
        <img className='double-chips' alt='double chips' src={doubleChips} />
      </div>
      <div className='title-bar'>
        <h3>{props.heading}</h3>
        <h4>{props.name}</h4>
        <div className='title-bar-background'></div>
      </div>
      <div className='image-holder'>
        <HollowChip className='hollow-chip' />
        <BackgroundImage data={props.backgroundImage} />
      </div>

      <div className='info-holder d-lg-none'>
        {props.buttonText && (
          <Button
            style={{
              color: props.metadata.buttonTextColor,
              backgroundColor: props.metadata.buttonColor,
              borderColor: props.metadata.buttonColor,
            }}
            tabIndex='0'
            variant='primary'
            onClick={buttonClick}
          >
            {props.buttonText}
          </Button>
        )}
        <div dangerouslySetInnerHTML={{ __html: props.description }}></div>
      </div>
      <div className='info-holder d-none d-lg-block'>
        <div dangerouslySetInnerHTML={{ __html: props.description }}></div>
        {props.buttonText && (
          <Button
            style={{
              color: props.metadata.buttonTextColor,
              backgroundColor: props.metadata.buttonColor,
              borderColor: props.metadata.buttonColor,
            }}
            tabIndex='0'
            variant='primary'
            onClick={buttonClick}
          >
            {props.buttonText}
          </Button>
        )}
      </div>
    </section>
  );
};

export default FeaturedCreator;
