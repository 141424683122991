import React from 'react';
import WinnerComponent from '../components/winners/Winner';
import Helmet from 'react-helmet';

const Winner = () => {
  return (
    <>
      <Helmet>
        <title> - Winner</title>
      </Helmet>
      <WinnerComponent />
    </>
  );
};

export default Winner;
