import React from 'react';
import { Helmet } from 'react-helmet';
import AllActivityComponent from '../components/all-activity/AllActivity';

const AllActivity = ({ metadata }) => {
  return (
    <>
      <Helmet>
        <title> - All Activity</title>
      </Helmet>
      <AllActivityComponent metadata={metadata} />
    </>
  );
};

export default AllActivity;
