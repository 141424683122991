import React, { useEffect } from 'react';
import { Switch, Route, Redirect, useHistory } from 'react-router-dom';
import UsernameScreen from './UsernameScreen';
import AvatarScreen from './AvatarScreen';
import InterestsScreen from './InterestsScreen';
import SocialsScreen from './SocialsScreen';
import AddressScreen from './AddressScreen';
import HeaderScreen from './HeaderScreen';
import FavoritesScreen from './FavoritesScreen';
import PageLoader from '../PageLoader';
import { formatContentData } from '../../Helpers';

import { useReactiveVar } from '@apollo/client';
import { userLoggedIn } from '../../graphql/reactivities/user';

import { useQuery } from '@apollo/client';
import { GET_CONTENT } from '../../graphql/queries/content.js';
import ErrorComponent from '../ErrorComponent';

const SetupProfileComponent = () => {
  let history = useHistory();

  const loggedIn = useReactiveVar(userLoggedIn);

  if (!loggedIn) {
    history.push('/');
  }

  const { data, loading, error } = useQuery(GET_CONTENT, {
    variables: { filter: `{"tag": "profile"}`, fetchPolicy: 'no-cache' },
  });

  if (loading || !data) return <PageLoader />;
  if (error || !data.getDoritosLotbContentListing.edges[0])
    return <ErrorComponent />;

  const setup = data.getDoritosLotbContentListing.edges.reduce(function (
    map,
    obj
  ) {
    map[obj.node.slug] = obj.node;
    return map;
  },
  {});

  const username = formatContentData(setup, 'setup-username');
  const avatar = formatContentData(setup, 'setup-avatar');
  const header = formatContentData(setup, 'setup-header');
  const favorites = formatContentData(setup, 'setup-favorites');
  const interests = formatContentData(setup, 'setup-interests');
  const social = formatContentData(setup, 'setup-social');
  const address = formatContentData(setup, 'setup-address');

  return (
    <>
      <section className='setup-profile'>
        <Switch>
          {/* REDIRECT TO FIRST SCREEN IF URL IS /setup */}
          <Route exact path='/setup/'>
            <Redirect to={'/setup/1'} />
          </Route>

          {/* SCREEN 1 - PROFILE NAME */}
          <Route exact path='/setup/1'>
            <UsernameScreen setup={username} />
          </Route>

          {/* SCREEN 2 - AVATAR */}
          <Route exact path='/setup/2'>
            <AvatarScreen setup={avatar} />
          </Route>

          {/* SCREEN 3 - PROFILE HEADER */}
          <Route exact path='/setup/3'>
            <HeaderScreen setup={header} />
          </Route>

          {/* SCREEN 4 - FAVORITES */}
          <Route exact path='/setup/4'>
            <FavoritesScreen setup={favorites} />
          </Route>

          {/* SCREEN 5 - INTERESTS */}
          <Route exact path='/setup/5'>
            <InterestsScreen setup={interests} />
          </Route>

          {/* SCREEN 6 - SOCIALS */}
          <Route exact path='/setup/6'>
            <SocialsScreen setup={social} />
          </Route>

          {/* SCREEN 7 - ADDRESS */}
          <Route exact path='/setup/7'>
            <AddressScreen setup={address} />
          </Route>
        </Switch>
      </section>
    </>
  );
};

export default SetupProfileComponent;
