import { gql } from '@apollo/client';

export const GET_CHALLENGE = gql`
  query getChallenge($id: Int!, $uid: Int!, $oid: Int!) {
    getDoritosLotbChallenge(id: $id) {
      id
      title
      assignment
      objective
      deliverables
      topprize
      interest
      category {
        ... on object_DoritosLotbChallengeType {
          name
          icon {
            ... on asset {
              fullpath
            }
          }
        }
      }
      active
      closed
      announced
      status
      winnersannounced
      maxentries
      maxattachments
      image {
        fullpath
      }
      background {
        fullpath
      }
      attachments {
        ... on object_DoritosLotbChallengeAttachment {
          id
          title
          attachmenttype
          attachment {
            ... on asset {
              fullpath
            }
          }
        }
      }
      cash {
        cash_userlevel {
          ... on object_DoritosLotbUserLevel {
            tier
            points
          }
        }
        cash_prizes
        cash_message
      }
      experiences {
        experiences_userlevel {
          ... on object_DoritosLotbUserLevel {
            tier
            points
          }
        }
        experiences_prizes
        experiences_message
      }
      products {
        products_userlevel {
          ... on object_DoritosLotbUserLevel {
            tier
            points
          }
        }
        products_prizes
        products_message
      }
      winners {
        ... on object_DoritosLotbWinner {
          id
          slug
          likes
          place
          prize
          description
          thumbnail {
            fullpath
          }
          user {
            ... on object_DoritosLotbUser {
              username
              avatar {
                ... on object_DoritosLotbAvatar {
                  image {
                    fullpath
                  }
                }
              }
            }
          }
          challenge {
            ... on object_DoritosLotbChallenge {
              category {
                ... on object_DoritosLotbChallengeType {
                  name
                  icon {
                    ... on asset {
                      fullpath
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    getInterest(uid: $uid, oid: $oid) {
      interest
    }
  }
`;
