import React from 'react';
import { Spinner } from 'react-bootstrap';

const ComponentLoader = (props) => {
  return (
    <div
      className={`component-loader ${props.className ? props.className : ''}`}
    >
      <Spinner animation='border' role='status'>
        <span className='sr-only'>Loading...</span>
      </Spinner>
    </div>
  );
};

export default ComponentLoader;
