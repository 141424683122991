import React, { useState } from 'react';
import TabComponent from './TabComponent';
import { formatDate } from '../../Helpers';

const ChallengeInfo = ({ data, open, loggedIn, interest, setInterest }) => {
  let interestLevel = -1;
  if (interest == 'working on it') {
    interestLevel = 0;
  } else if (interest == 'interested') {
    interestLevel = 1;
  } else if (interest == 'not interested') {
    interestLevel = 2;
  }

  const [selectedIndex, setSelectedIndex] = useState(interestLevel);
  const handleInterestLevelChange = (e) => {
    let interestLevel = parseInt(e.target.value);
    setSelectedIndex(interestLevel);
    let activity = 'working on it';
    if (interestLevel == 1) activity = 'interested';
    if (interestLevel == 2) activity = 'not interested';
    setInterest(activity);
  };

  return (
    <div className='challenge-info'>
      <h1
        dangerouslySetInnerHTML={{
          __html: (data.title) ? data.title.replace(/[®]/g, '<sup>&reg;</sup>') : '',
        }}
      ></h1>
      <div className='prize-info'>
        {data.prize && (
          <p className='info-label'>Top Prize {data.topprize}</p>
        )}
        {data.status && <p className='info-label'>Status - {data.status}</p>}
      </div>
      <TabComponent data={data} />
      <div className='submission-info'>
        {data.closed && (
          <p className='info-label'>
            Submissions Due: {formatDate(data.closed)}
          </p>
        )}
        {data.announced && (
          <p className='info-label'>
            Winners Announced: {formatDate(data.announced)}
          </p>
        )}
      </div>
      {open && loggedIn && (
        <div className='submission-interest-level'>
          <p>Interest Level:</p>
          <ul>
            <li>
              <input
                id='interest-on-it'
                className='radio-button'
                tabIndex='0'
                type='radio'
                value={0}
                onChange={handleInterestLevelChange}
                checked={selectedIndex === 0}
              />
              <span className='radio-button-checkmark'></span>
              <label htmlFor='interest-on-it'>I'm On It</label>
            </li>

            <li>
              <input
                id='interest-maybe'
                className='radio-button'
                tabIndex='0'
                type='radio'
                value={1}
                onChange={handleInterestLevelChange}
                checked={selectedIndex === 1}
              />
              <span className='radio-button-checkmark'></span>
              <label htmlFor='interest-maybe'>Maybe</label>
            </li>

            <li>
              <input
                id='interest-not-interested'
                className='radio-button'
                tabIndex='0'
                type='radio'
                value={2}
                onChange={handleInterestLevelChange}
                checked={selectedIndex === 2}
              />
              <span className='radio-button-checkmark'></span>
              <label htmlFor='interest-not-interested'>Not Interested</label>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default ChallengeInfo;
