import React from 'react';
import { Helmet } from 'react-helmet';
import GeneralPage from '../components/GeneralPage';

const FourOhFour = () => {
  return (
    <>
      <Helmet>
        <title> - Page Not Found</title>
      </Helmet>
      <GeneralPage>
        <h1>404</h1>
        <p>page not found</p>
      </GeneralPage>
    </>
  );
};

export default FourOhFour;
