import React from 'react';
import ItemsList from '../ItemsList';

const ChallengeSubmissionTags = ({
  tags,
  selectedTags,
  addRemoveTag,
  editing,
}) => {
  if (!editing && (!selectedTags || selectedTags.length === 0))
    return <p>No tags selected</p>;
  return (
    <>
      <ItemsList
        items={
          editing
            ? tags
            : tags.filter((tag) =>
                selectedTags.some((f) => f === tag.node.name)
              )
        }
        onClicked={addRemoveTag}
        selectedItems={selectedTags}
        allowSelect={editing}
        showAll={editing}
      />
    </>
  );
};

export default ChallengeSubmissionTags;
