import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Navigation from './components/Navigation';
import Layout from './components/Layout';
import Home from './pages/home';
import AllActivity from './pages/all-activity';
import SetupProfile from './pages/setup-profile';
import EditUser from './pages/edit-user';
import Challenges from './pages/challenges';
import Challenge from './pages/challenge';
import Winners from './pages/winners';
import Winner from './pages/winner';
import Terms from './pages/terms';
import Privacy from './pages/privacy';
import User from './pages/user';
import Footer from './components/Footer';
import FourOhFour from './pages/four-o-four';
import ScrollToTop from './components/ScrollToTop';

import cookie from 'js-cookie';
import { useQuery } from '@apollo/client';
import { GET_CONTENT } from './graphql/queries/content.js';
import PageLoader from './components/PageLoader';
import MaintenanceComponent from './components/MaintenanceComponent';

function App() {

  console.log('DLoC v1.0.7');

  if (!cookie.get('active')) {
    localStorage.clear();
  }

  const { data, loading, error } = useQuery(GET_CONTENT, {
    variables: { filter: `{"slug": "metadata"}`, fetchPolicy: 'no-cache' },
  });

  if (loading) return <PageLoader />;
  if (error) {
    console.log('App::useQuery::GET_CONTENT::Error');
    localStorage.clear();
    return <p></p>;
  }
  if (!data || !data.getDoritosLotbContentListing.edges[0])
    return <PageLoader />;

  const metadata = data.getDoritosLotbContentListing.edges[0].node.metadata.reduce(
    function (map, obj) {
      map[obj.key] = obj.value;
      return map;
    },
    {}
  );


  if (metadata.maintenanceMode) {
    return <MaintenanceComponent message={metadata.maintenanceMode} />;
  }

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Navigation metadata={metadata} />
      <Layout metadata={metadata}>
        <Switch>
          {/* HOME */}
          <Route exact path='/'>
            <Home metadata={metadata} />
          </Route>

          {/* AllActivity */}
          <Route exact path='/all-activity'>
            <AllActivity metadata={metadata} />
          </Route>

          {/* CHALLENGES */}
          <Route exact path='/challenges'>
            <Challenges metadata={metadata} />
          </Route>

          {/* CHALLENGE DETAIL */}
          <Route
            path='/challenges/:id'
            render={(props) => <Challenge id={props.match.params.id} />}
          />

          {/* EDIT PROFILE */}
          <Route
            path='/users/:username/edit'
            render={(props) => (
              <EditUser username={props.match.params.username} />
            )}
          />

          {/* USER */}
          <Route
            path='/users/:username'
            render={(props) => (
              <User
                metadata={metadata}
                username={props.match.params.username}
              />
            )}
          />

          {/* SETUP PROFILE */}
          <Route path='/setup'>
            <SetupProfile />
          </Route>

          {/* WINNER */}
          <Route exact path='/winners/'>
            <Winners metadata={metadata} />
          </Route>

          {/* WINNER */}
          <Route
            path='/winners/:id'
            render={(props) => <Winner id={props.match.params.id} />}
          />

          {/* TERMS */}
          <Route exact path='/terms'>
            <Terms />
          </Route>

          {/* PRIVACY */}
          <Route exact path='/privacy'>
            <Privacy />
          </Route>

          {/* CATCH ALL REDIRECT */}
          <FourOhFour />
        </Switch>
        <Footer metadata={metadata} />
      </Layout>
    </BrowserRouter>
  );
}

export default App;
