import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_ALL_INTERESTS } from '../graphql/queries/profile.js';
import ItemsList from './ItemsList.jsx';
import ComponentLoader from './ComponentLoader';
import ErrorComponent from './ErrorComponent.jsx';

const InterestsList = ({
  interestSelected,
  selectedInterests,
  showAll = true,
  allowSelect = false,
}) => {
  const { loading, error, data } = useQuery(GET_ALL_INTERESTS);

  if (loading || !data) return <ComponentLoader />;
  if (error || !data.getDoritosLotbInterestListing.edges[0])
    return <ErrorComponent />;

  const allInterests = data.getDoritosLotbInterestListing.edges;

  const onClicked = (e) => {
    interestSelected(e.target.dataset.name);
  };

  return (
    <ItemsList
      items={
        showAll
          ? allInterests
          : allInterests.filter((interest) =>
              selectedInterests.some((f) => f === interest.node.name)
            )
      }
      onClicked={onClicked}
      selectedItems={selectedInterests}
      showAll={showAll}
      allowSelect={allowSelect}
    />
  );
};

export default InterestsList;
