import nodeUrl from 'url';

export function buildSignInURL ( url ) {
  const _url = nodeUrl.parse(window.location.href);
  const domain = _url.protocol + '//' + _url.host;
  return url.replace('#URL#',domain) + '&state=' + _url.path;
}

export function getIdFromPath () {
  const url = nodeUrl.parse(window.location.href);
  const parts = url.path.split('-');
  return parseInt( parts.pop() );
}

export function formatContentData ( content, slug ) {

  if ( content[slug] ) {
    let data = {...content[slug]};

    // FORMAT METADATA
    const metadata = data.metadata.reduce( function ( map, obj ) {
      if ( obj.value === 'true' || obj.value === 'false' ) {
        map[obj.key] = ( obj.value === 'true' ) ? true : false;
      } else {
        map[obj.key] = obj.value;
      }
      return map;
      }, {} );
    data.metadata = metadata;

    // FORMAT IMAGES
    let images = {};
    if ( data.image ) {
      data.image.forEach((obj, index) => {
        images[obj.slug] = {
          alt: obj.alt,
          mobile: {
            url: (obj.mobileImage) ? obj.mobileImage.fullpath : null,
            width: obj.mobileWidth
          },
          desktop: {
            url: (obj.image) ? obj.image.fullpath : null,
            width: obj.width
          }
        }
      });
    }
    data.image = images;

    return data;
  }

  return null;
}

export function formatDate ( date ) {
  let dateTimeParts = date.split(/[- :]/);
  dateTimeParts[1]--;
  const dateObject = new Date(...dateTimeParts);
  return dateObject.toLocaleDateString('default', { year: 'numeric', month: 'long', day: 'numeric' });
}

export function challengeOpen ( challenge ) {
  if ( challenge.status ) {
    const status = challenge.status.toLowerCase();
    return status.includes('open') ? true : false;
  }
  return false;
}

export function getUserTier ( user ) {
  if ( user && user.tier ) {
    return user.tier;
  }
  return 'Noob';
}
