import React, { useRef } from 'react';
import VisionBoardCard from './VisionBoardCard';
import { ReactComponent as HollowChip } from '../assets/svg/hollow-chip.svg';
import { ReactComponent as FullChip } from '../assets/svg/full-chip.svg';

const VisionBoardNoQuery = (props) => {
  const redHollowChipRef = useRef(null);
  const greenHollowChipRef = useRef(null);
  const fullChipRef = useRef(null);
  const renderBoard = (items) =>
    items.map((item) => (
      <VisionBoardCard key={item.node.id} data={item.node} />
    ));

  return (
    <section
      className={`vision-board ${props.className ? props.className : ''}`}
    >
      <div className='card-holder'>{renderBoard(props.cards)}</div>
      <div className='vision-board-background'>
        <HollowChip className='green-hollow-chip' ref={greenHollowChipRef} />
        <HollowChip className='red-hollow-chip' ref={redHollowChipRef} />
        <FullChip className='full-chip' ref={fullChipRef} />
      </div>
    </section>
  );
};

export default VisionBoardNoQuery;
